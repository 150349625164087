import React from 'react'

import CallToAction from '../call-to-action'
import Hero from '../hero'
import LatestReviews from '../latest-reviews'
import TopCategories from '../top-categories'

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <TopCategories />
      <LatestReviews />
      <CallToAction style={3} />
    </>
  )
}

export default Home
