import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { useGetCategoriesListQuery } from '../../services/category'
import './style.css'

interface IHero {
  style?: number
}

const Hero: React.FC<IHero> = ({ style }) => {
  const navigate = useNavigate()
  const { data, isLoading } = useGetCategoriesListQuery({ limit: 100, page: 1 })
  let options = [{ label: 'All Categories', value: '' }]
  if (data?.length) {
    const categories = JSON.stringify(
      data.map(({ _id, name }) => ({ label: name, value: _id }))
    )

    options = [...new Set([...options, ...JSON.parse(categories)])]
  }
  const searchField = useRef<HTMLInputElement>(null)

  const handleSubmitSearch = (event: any) => {
    event.preventDefault()
    const category = event.target.category.value
    const search = searchField.current?.value
    navigate(`/listing-reviews?search=${search}&category=${category}`)
  }

  if (style === 2) {
    return (
      <section className="hero_single version_2">
        <div className="wrapper">
          <div className="container">
            <div className="row justify-content-center pt-lg-5">
              <div className="col-xl-5 col-lg-6">
                <h3>
                  Modern Customer
                  <br />
                  Support Platform
                </h3>
                <p>
                  Find advanced customer support, enablement services and
                  solutions carefully designed, reviewed and selected by our
                  team of specialists
                </p>
                <form onSubmit={handleSubmitSearch}>
                  <div className="custom-search-input-2">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="What are you looking for..."
                        name="search"
                        ref={searchField}
                      />
                      <i className="icon_search" />
                    </div>
                    <Select
                      className="category-select"
                      classNamePrefix="rv-custom-select"
                      options={options}
                      defaultValue={options[0]}
                      isSearchable={false}
                      isLoading={isLoading}
                      name="category"
                    />
                    <input type="submit" defaultValue="Search" />
                  </div>
                </form>
              </div>
              <div className="col-xl-5 col-lg-6 d-none d-lg-block text-end">
                <img
                  src="/images/graphic_home_2.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="hero_single version_1">
      <div className="wrapper">
        <div className="container">
          <h3>Modern Customer Support Platform</h3>
          <p>
            Find advanced customer support, enablement services and solutions{' '}
            <br />
            carefully designed, reviewed and selected by our team of specialists
          </p>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <form onSubmit={handleSubmitSearch}>
                <div className="row g-0 custom-search-input-2">
                  <div className="col-lg-7">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="What are you looking for..."
                        ref={searchField}
                      />
                      <i className="icon_search" />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <Select
                      className="category-select"
                      classNamePrefix="rv-custom-select"
                      options={options}
                      defaultValue={options[0]}
                      isSearchable={false}
                      isLoading={isLoading}
                      name="category"
                    />
                  </div>
                  <div className="col-lg-2">
                    <input type="submit" defaultValue="Search" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
