import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

function Menu() {
  return (
    <nav id="menu" className="main-menu">
      <ul>
        <li>
          <span>
            <Link to="/">Home</Link>
          </span>
          {/* <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/home-2">Home version 2 (GDPR)</Link>
            </li>
          </ul> */}
        </li>
        <li>
          <span>
            <Link to="/listing-reviews">Reviews</Link>
          </span>
          {/* <ul>
            <li>
              <span>
                <Link to="/listing-reviews">Layouts</Link>
              </span>
              <ul>
                <li>
                  <Link to="/listing-reviews">Grid listings 1</Link>
                </li>
                <li>
                  <a href="grid-listings-filterscol.html">Grid listings 2</a>
                </li>
                <li>
                  <a href="row-listings-filterscol.html">Row listings</a>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/listing-reviews">Reviews page</Link>
            </li>
            <li>
              <Link to="/user-dashboard">User Dashboard</Link>
            </li>
            <li>
              <Link to="/user-settings">User Settings</Link>
            </li>
          </ul> */}
        </li>
        <li>
          <span>
            <Link to="/all-categories">Categories</Link>
          </span>
        </li>
        <li>
          <span>
            <Link to="/all-products">Products</Link>
          </span>
        </li>
        {/* <li>
          <span>
            <a href="#0">Pages</a>
          </span>
          <ul>
            <li>
              <Link to="/companies">Companies Landing Page</Link>
            </li>
            <li>
              <Link to="/all-categories">Companies Categories Page</Link>
            </li>
            <li>
              <Link to="/category-companies">Companies Listing Page</Link>
            </li>
            <li>
              <a href="blog.html">Blog</a>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/register">Register</Link>
            </li>
            <li>
              <a href="about.html">About</a>
            </li>
            <li>
              <a href="help.html">Help Section</a>
            </li>
            <li>
              <a href="faq.html">Faq Section</a>
            </li>
            <li>
              <a href="contacts.html">Contacts</a>
            </li>
            <li>
              <span>
                <a href="#0">Icon Packs</a>
              </span>
              <ul>
                <li>
                  <a href="icon-pack-1.html">Icon pack 1</a>
                </li>
                <li>
                  <a href="icon-pack-2.html">Icon pack 2</a>
                </li>
                <li>
                  <a href="icon-pack-3.html">Icon pack 3</a>
                </li>
                <li>
                  <a href="icon-pack-4.html">Icon pack 4</a>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/404">404 page</Link>
            </li>
          </ul>
        </li> */}
      </ul>
    </nav>
  )
}

export default Menu
