import { useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { RootNavigationRoute } from '../constants/navigation'

import Footer from './footer'
import Header from './header'

const Layout: React.FC = () => {
  const location = useLocation()

  const headerStyle = useMemo(() => {
    if (location.pathname.includes('/product/')) {
      return 'fixed'
    }
    switch (location.pathname) {
      case RootNavigationRoute.REVIEWS_PAGE:
      case RootNavigationRoute.WRITE_REVIEW:
      case RootNavigationRoute.QUOTE:
      case RootNavigationRoute.USER_DASHBOARD:
      case RootNavigationRoute.USER_SETTINGS:
        return 'fixed'
      case RootNavigationRoute.HOME:
      case RootNavigationRoute.HOME2:
      case RootNavigationRoute.COMPANIES:
      case RootNavigationRoute.ABOUT_US:
      case RootNavigationRoute.FAQ:
      case RootNavigationRoute.HELP:
      case RootNavigationRoute.CONTACTS:
      case RootNavigationRoute.BLOG:
      case RootNavigationRoute.POST_CATEGORY:
      case RootNavigationRoute.BLOG_POST:
      case RootNavigationRoute.ALL_CATEGORIES:
        return 'sticky'
      case RootNavigationRoute.LISTING_REVIEWS:
      case RootNavigationRoute.CATEGORY_COMPANIES:
      case RootNavigationRoute.ALL_PRODUCTS:
      case RootNavigationRoute.PRIVACY:
      case RootNavigationRoute.TERM_OF_USE:
        return ''
      default:
        return ''
    }
  }, [location.pathname])

  const isPageEmpty = [
    RootNavigationRoute.LOGIN as string,
    RootNavigationRoute.REGISTER,
    RootNavigationRoute.FORGOT_PASSWORD,
    RootNavigationRoute.RESET_PASSWORD,
  ].includes(location.pathname)

  return (
    <>
      {!isPageEmpty && <Header style={headerStyle} />}
      <main>
        <Outlet />
      </main>
      {!isPageEmpty && <Footer />}
    </>
  )
}

export default Layout
