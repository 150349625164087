import React from 'react'
import { useParams } from 'react-router-dom'

import { useGetProductByIdQuery } from '../../services/product'
import { useGetReviewsByCompanyIdQuery } from '../../services/review'
import type { Review } from '../../services/review/types'
import { timeStampToDate } from '../../utils/helpers'
import ProductInformation from '../company-information'
// import Pagination from '../pagination'
import ReviewCard from '../review-card'
import ReviewsSummary from '../reviews-summary'

import './style.css'

const ReviewsPage: React.FC = () => {
  const { slug } = useParams()
  const companyId = slug ? slug.split('-').pop() : ''

  const { data, isLoading } = useGetProductByIdQuery(companyId as string, {
    skip: !companyId,
  })
  const { data: reviewData, isLoading: isLoadingReview } =
    useGetReviewsByCompanyIdQuery(companyId as string, {
      skip: !companyId,
    })

  if (!companyId || (!isLoading && !data))
    return (
      <div className="full-width-auto">
        <div className="text-center">Product is not exist!</div>
      </div>
    )

  const reviews = () => {
    if (isLoadingReview) {
      return Array.from({ length: 6 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="review_card is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="row">
                <div className="col-md-2 user_info">
                  <figure className="image-loading"></figure>
                  <h5>&nbsp;</h5>
                </div>
                <div className="col-md-10 review_content">
                  <h4>&nbsp;</h4>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          )
        })
    }

    if (!reviewData || !reviewData.length) return null

    return reviewData.map((review: Review) => (
      <ReviewCard
        image={review?.owner_ref?.avatar || '/images/avatar4.jpeg'}
        author={`${review?.owner_ref?.first_name} ${review?.owner_ref?.last_name}`}
        published={timeStampToDate(review.created_at)}
        title={review.title}
        description={review.content || ''}
        key={review._id}
        rating={review.rating}
      />
    ))
  }

  const reviewSummary = () => {
    if (isLoading) {
      return (
        <div className="reviews_summary is-loading-skeleton">
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <figure className="image-loading"></figure>
                  <h1>&nbsp;</h1>
                </div>
                <div className="col-lg-4 review_detail">
                  <div className="progress-bar-loading"></div>
                  <div className="progress-bar-loading"></div>
                  <div className="progress-bar-loading"></div>
                  <div className="progress-bar-loading"></div>
                  <div className="progress-bar-loading"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (!data) return null
    return (
      <ReviewsSummary
        name={data?.name as string}
        category={data?.categories_ref?.[0]?.name}
        avatar={data?.avatar}
        review_summary={data?.review_summary}
        slug={data?.slug as string}
        cover_image={data?.cover_image}
        address={data?.address}
        website={data?.website}
      />
    )
  }

  const companyInformation = () => {
    if (isLoading) {
      return (
        <div className="box_general company_info is-loading-skeleton">
          <h1>&nbsp;</h1>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      )
    }
    if (!data) return null
    return (
      <ProductInformation
        name={data?.name as string}
        description={data?.bio}
        address={data?.address}
        website={data?.website}
        country={data?.country}
        telephone={data?.telephone}
        net_promoter_score={data?.net_promoter_score}
        company={data?.company}
      />
    )
  }

  return (
    <>
      {reviewSummary()}
      <div className="margin_60_35 container">
        <div className="row">
          <div className="col-lg-8">
            {reviews()}
            {/* <Pagination /> */}
          </div>
          <div className="col-lg-4">{companyInformation()}</div>
        </div>
      </div>
    </>
  )
}

export default ReviewsPage
