import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { userApi } from '../../services/user'

import './style.css'

const RequireUser: React.FC = () => {
  const location = useLocation()
  const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: false,
  })

  const loading = isLoading || isFetching
  const user = userApi.endpoints.getMe.useQueryState(null)

  if (loading)
    return (
      <div className="full-width-auto">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireUser
