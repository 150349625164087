export interface ILoginRequest {
  email: string
  password: string
}
export interface ILoginResponse {
  user: IUser | null
  accessToken: string
}

export interface IUser {
  _id: string
  first_name: string
  last_name: string
  email: string
  password: string
  bio?: string
  avatar?: string
  city?: string
  country?: string
  total_reviews?: number
  total_useful?: number
}
export interface IRegisterRequest {
  first_name: string
  last_name: string
  email: string
  password: string
  bio?: string
  avatar?: string
  city?: string
  country?: string
  login_type?: LoginType
}

export interface IUpdateProfileRequest {
  first_name: string
  last_name: string
  password?: string
  bio?: string
  avatar?: string
  city?: string
  country?: string
}

export interface IDeleteStatus {
  success: boolean
  message: string
}

export interface IResetPassword {
  email: string
  password: string
  token: string
}

export enum LoginType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  NORMAL = 'normal',
}
