import React from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  useGetPostsByCategoryIdQuery,
  useGetPostsListQuery,
} from '../../services/post'
import {
  timeStampToDateShort,
  truncateAndDecodeHTML,
} from '../../utils/helpers'
import BlogSidebar from '../blog-sidebar'
import './style.css'

const Blog: React.FC = () => {
  const { slug } = useParams()
  const categoryId = slug ? slug.split('-').pop() : ''

  const { data, isLoading } = useGetPostsListQuery(
    { limit: 100, page: 1 },
    { skip: !!categoryId }
  )
  const { data: postCategories, isLoading: isLoadingPostCategories } =
    useGetPostsByCategoryIdQuery(categoryId as string, {
      skip: !categoryId,
    })

  const listPost = () => {
    if (isLoading || isLoadingPostCategories) {
      return Array.from({ length: 8 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="col-md-6 is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="image-loading"></div>
              <h3>&nbsp;</h3>
              <h4>&nbsp;</h4>
            </div>
          )
        })
    }

    return (data || postCategories)?.map((post) => (
      <div key={post._id} className="col-md-6">
        <article className="blog">
          <figure>
            <Link to={`/post/${post?.slug}`}>
              <img src={post?.thumbnail} alt={post?.name} />
              <div className="preview">
                <span>Read more</span>
              </div>
            </Link>
          </figure>
          <div className="post_info">
            <small>
              {post?.post_category_ref?.name} -{' '}
              {timeStampToDateShort(post.created_at)}
            </small>
            <h2>
              <Link to={`/post/${post?.slug}`}>{post?.name}</Link>
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: truncateAndDecodeHTML(post?.content, 100),
              }}
            />
            <ul>
              <li>
                <div className="thumb">
                  <img
                    src={post?.author_ref?.avatar || '/images/avatar4.jpeg'}
                    alt=""
                  />
                </div>{' '}
                {[
                  post?.author_ref?.first_name,
                  post?.author_ref?.last_name,
                ].join(' ')}
              </li>
              <li>
                <i className="ti-comment" />
                20
              </li>
            </ul>
          </div>
        </article>
      </div>
    ))
  }

  return (
    <>
      <section className="hero_single general">
        <div className="wrapper">
          <div className="container">
            <h1>Customer Advocates Blog</h1>
            <p>
              Customer Advocates helps grow your business using customer reviews
            </p>
          </div>
        </div>
      </section>

      <div className="margin_60_35 container">
        <div className="row">
          <div className="col-lg-9">
            <div className="row">{listPost()}</div>
            {/* <div className="pagination__wrapper add_bottom_30">
              <ul className="pagination">
                <li>
                  <a href="#0" className="prev" title="previous page">
                    ❮
                  </a>
                </li>
                <li>
                  <a href="#0" className="active">
                    1
                  </a>
                </li>
                <li>
                  <a href="#0">2</a>
                </li>
                <li>
                  <a href="#0">3</a>
                </li>
                <li>
                  <a href="#0">4</a>
                </li>
                <li>
                  <a href="#0" className="next" title="next page">
                    ❯
                  </a>
                </li>
              </ul>
            </div> */}
            {/* /pagination */}
          </div>
          {/* /col */}
          <BlogSidebar />
          {/* /aside */}
        </div>
        {/* /row */}
      </div>
    </>
  )
}

export default Blog
