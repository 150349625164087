import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { IResolveParams } from 'reactjs-social-login'
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login'
import * as Yup from 'yup'

import { FB_APP_ID, GG_APP_ID } from '../../constants/social-apps-id'
import { useLoginMutation, useLoginSocialMutation } from '../../services/user'
import { LoginType } from '../../services/user/types'

import './style.css'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const [loginMutation] = useLoginMutation()
  const [loginSocialMutation] = useLoginSocialMutation()

  useEffect(() => {
    document.body.classList.add('body_bg')
    return () => {
      document.body.classList.remove('body_bg')
    }
  })

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result: any = await loginMutation(values)
        if (result?.data?.accessToken) {
          toast.success('Login successful', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
          navigate('/user-dashboard')
        } else {
          toast.error(result?.error?.data?.message || 'Something went wrong!', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        }
        setSubmitting(false)
      } catch (err: any) {
        toast.error(err?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: SignInSchema,
  })

  const REDIRECT_URI =
    'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login'
  const onLoginStart = useCallback(() => {}, [])

  const _handleSocialLogin = async (
    provider: LoginType.GOOGLE | LoginType.FACEBOOK,
    data: any
  ) => {
    try {
      const sanitizeValues = {
        avatar: '',
        email: data?.email,
        first_name: '',
        last_name: '',
        login_type: LoginType.GOOGLE,
        password: '',
      }
      if (provider === LoginType.GOOGLE) {
        sanitizeValues.avatar = data?.picture
        sanitizeValues.first_name = data?.given_name
        sanitizeValues.last_name = data?.family_name
        sanitizeValues.login_type = LoginType.GOOGLE
        sanitizeValues.password = data?.sub
      } else {
        sanitizeValues.first_name = data?.first_name
        sanitizeValues.first_name = data?.last_name
        sanitizeValues.avatar = data?.picture?.data?.url
        sanitizeValues.login_type = LoginType.FACEBOOK
        sanitizeValues.password = data?.userID
      }
      const result: any = await loginSocialMutation(sanitizeValues)
      if (result?.data?.accessToken) {
        toast.success('Login successful', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        navigate('/user-dashboard')
      } else {
        toast.error(result?.error?.data?.message || 'Something went wrong!', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (err: any) {
      toast.error(err?.data?.message, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
        theme: 'light',
      })
    }
  }

  return (
    <div id="login">
      <aside>
        <figure>
          <Link to="/">
            <img
              src="/images/methodobjects.png"
              // width="140"
              height="35"
              alt=""
              className="logo_sticky"
            />
          </Link>
        </figure>

        <form onSubmit={formik.handleSubmit}>
          <div className="access_social">
            <LoginSocialFacebook
              appId={FB_APP_ID}
              fieldsProfile={
                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
              }
              onLoginStart={onLoginStart}
              onLogoutSuccess={() => {}}
              redirect_uri={REDIRECT_URI}
              onResolve={({ provider, data }: IResolveParams) => {
                if (data) {
                  _handleSocialLogin(provider as LoginType.FACEBOOK, data)
                }
              }}
              onReject={(err) => {
                // eslint-disable-next-line no-console
                console.log(err)
              }}
            >
              <div className="social_bt facebook">Login with Facebook</div>
            </LoginSocialFacebook>
            <LoginSocialGoogle
              client_id={GG_APP_ID}
              onLoginStart={onLoginStart}
              redirect_uri={REDIRECT_URI}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }: IResolveParams) => {
                if (data) {
                  _handleSocialLogin(provider as LoginType.GOOGLE, data)
                }
              }}
              onReject={(err) => {
                // eslint-disable-next-line no-console
                console.log(err)
              }}
            >
              <div className="social_bt google">Login with Google</div>
            </LoginSocialGoogle>
          </div>
          <div className="divider">
            <span>Or</span>
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <i className="icon_mail_alt" />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback-rv">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              name="password"
              id="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <i className="icon_lock_alt" />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback-rv">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className="clearfix add_bottom_30">
            <div className="checkboxes float-start">
              <label className="container_check">
                Remember me
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </div>
            <div className="float-end mt-1">
              <Link id="forgot" to="/forgot-password">
                Forgot Password?
              </Link>
            </div>
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn_1 full-width rounded"
          >
            Login to Customer Advocates
          </button>
          <div className="add_top_10 text-center">
            New to Customer Advocates?{' '}
            <strong>
              <Link to="/register">Sign up!</Link>
            </strong>
          </div>
        </form>

        <div className="copy">© 2021 Customer Advocates</div>
      </aside>
    </div>
  )
}

export default Login
