import React from 'react'

import './style.css'

export type CheckboxProps = React.HTMLAttributes<HTMLInputElement> & {
  label?: string
  name: string
}

const Checkbox: React.FC<CheckboxProps> = ({ label, name, onChange }) => {
  return (
    <label className="container_check">
      {label}
      <input type="checkbox" name={name} onChange={onChange} />
      <span className="checkmark" />
    </label>
  )
}
export default Checkbox
