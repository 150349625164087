import React, { useState } from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.css'

const AboutUs: React.FC = () => {
  const [isValueTab, setIsValueTab] = useState<number>(0)

  const settings = {
    arrows: false,
    centerMode: true,
    centerPadding: '140px',
    className: 'center',
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '140px',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 999,
        settings: {
          centerPadding: '50px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
    slidesToShow: 3,
    speed: 500,
  }

  return (
    <>
      <section className="hero_single office">
        <div className="wrapper">
          <div className="container">
            <h1>About Customer Advocates</h1>
            <p>
              Customer Advocates helps grow your business using customer reviews
            </p>
          </div>
        </div>
      </section>

      <div className="margin_80 container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6">
            <img
              alt=""
              src="/images/office_2.jpeg"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-6 pl-lg-5 pt-4">
            <h2>Passion Drive Us</h2>
            <p className="lead">
              Dolor detraxit duo in, ei sea dicit reformidans. Mel te accumsan
              patrioque referrentur. Has causae perfecto ut, ex choro assueverit
              eum. Qui omnium cetero expetenda no, detracto vivendum corrumpit
              cu duo.
            </p>
            <p className="lead">Sed ne prompta insolens mediocrem.</p>
            <p className="lead">
              <em>Mark Twain CEO</em>
            </p>
          </div>
        </div>
      </div>
      <div className="bg_color_1">
        <div className="margin_80 container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 pl-lg-5 order-lg-last">
              <img
                alt=""
                src="/images/office_3.jpeg"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-lg-6 order-lg-first pt-4">
              <h2>Succes is our GOAL!</h2>
              <p className="lead">
                Vis at partem hendrerit, his te facete tacimates concludaturque,
                duo ex fabulas menandri. Idque saperet assentior mea an. Nisl
                copiosae reformidans duo ea, no doming elaboraret sed.
              </p>
              <p className="lead">
                Quod exerci torquatos id sit, ne vix officiis consetetur. Te
                viris corpora voluptaria mea, hendrerit prodesset no cum.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="values">
        <div className="wrapper">
          <div className="container">
            <div className="main_title_2">
              <h2>Our Values</h2>
              <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
            </div>
            <div
              className="row justify-content-center"
              style={{ minHeight: 210 }}
            >
              <div className="col-lg-5">
                <div
                  className="nav flex-column"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <span
                    className={`nav-link ${isValueTab === 0 ? 'active' : ''}`}
                    onClick={() => setIsValueTab(0)}
                    aria-hidden="true"
                  >
                    Helps consumers and companies
                  </span>
                  <span
                    className={`nav-link ${isValueTab === 1 ? 'active' : ''}`}
                    aria-hidden="true"
                    onClick={() => setIsValueTab(1)}
                  >
                    Shoppers and retailers benefits
                  </span>
                  <span
                    className={`nav-link ${isValueTab === 2 ? 'active' : ''}`}
                    aria-hidden="true"
                    onClick={() => setIsValueTab(2)}
                  >
                    Making e-commerce so divers
                  </span>
                  <span
                    className={`nav-link ${isValueTab === 3 ? 'active' : ''}`}
                    aria-hidden="true"
                    onClick={() => setIsValueTab(3)}
                  >
                    Assess their service daily
                  </span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="tab-content">
                  <div
                    className={`tab-pane fade ${
                      isValueTab === 0 ? 'show active' : ''
                    }`}
                    id="tab-1-content"
                    role="tabpanel"
                    aria-labelledby="tab-1"
                  >
                    <p className="lead">
                      Vis at partem hendrerit, his te facete tacimates
                      concludaturque, duo ex fabulas menandri. Idque saperet
                      assentior mea an. Nisl copiosae reformidans duo ea, no
                      doming elaboraret sed. Malorum cotidieque an cum.
                    </p>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      isValueTab === 1 ? 'show active' : ''
                    }`}
                    id="tab-2-content"
                    role="tabpanel"
                    aria-labelledby="tab-2"
                  >
                    <p className="lead">
                      Sed ne prompta insolens mediocrem, omnium fierent sed an,
                      quod vivendo mel in. Argumentum honestatis ad mel, cu vis
                      quot utroque. Nemore percipit no has. Mollis tincidunt his
                      ex, dolore inimicus no cum.
                    </p>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      isValueTab === 2 ? 'show active' : ''
                    }`}
                    id="tab-3-content"
                    role="tabpanel"
                    aria-labelledby="tab-3"
                  >
                    <p className="lead">
                      Quod exerci torquatos id sit, ne vix officiis consetetur.
                      Te viris corpora voluptaria mea, hendrerit prodesset no
                      cum. Has tota semper alterum ne, qui te suas sensibus. Duo
                      persius sensibus ne, choro soluta adolescens vim te, sale
                      scripta ex his.
                    </p>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      isValueTab === 3 ? 'show active' : ''
                    }`}
                    id="tab-4-content"
                    role="tabpanel"
                    aria-labelledby="tab-4"
                  >
                    <p className="lead">
                      Sumo periculis inciderint ius ex. Sit te fierent probatus
                      delicata, id mel nonumy consul oporteat. Agam tractatos te
                      eam, iisque vulputate moderatius cu sit. Oratio
                      complectitur contentiones nam ut, at legere maiorum
                      fierent duo. Mel ea vero aliquid.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="margin_80_55 container">
        <div className="main_title_2">
          <h2>Our founders</h2>
          <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
        </div>
        <Slider {...settings} className="founder-carousel">
          <div className="item">
            <a href="#0">
              <div className="title">
                <h4>
                  Julia Holmes<em>CEO</em>
                </h4>
              </div>
              <img src="/images/about/1_carousel.jpeg" alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#0">
              <div className="title">
                <h4>
                  Lucas Smith<em>Marketing</em>
                </h4>
              </div>
              <img src="/images/about/2_carousel.jpeg" alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#0">
              <div className="title">
                <h4>
                  Paul Stephens<em>Business strategist</em>
                </h4>
              </div>
              <img src="/images/about/3_carousel.jpeg" alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#0">
              <div className="title">
                <h4>
                  Pablo Himenez<em>Customer Service</em>
                </h4>
              </div>
              <img src="/images/about/4_carousel.jpeg" alt="" />
            </a>
          </div>
          <div className="item">
            <a href="#0">
              <div className="title">
                <h4>
                  Andrew Stuttgart<em>Admissions</em>
                </h4>
              </div>
              <img src="/images/about/5_carousel.jpeg" alt="" />
            </a>
          </div>
        </Slider>
      </div>
    </>
  )
}

export default AboutUs
