import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetProductsByCategoryIdQuery } from '../../services/product'
import type { Product } from '../../services/product/types'
import ProductItem from '../company-item'
import './style.css'

const CategoryCompanies: React.FC = () => {
  const { slug } = useParams()
  const categoryId = slug ? slug.split('-').pop() : ''
  const { data, isLoading } = useGetProductsByCategoryIdQuery(
    categoryId as string,
    {
      skip: !categoryId,
    }
  )
  const companies = useMemo(() => {
    if (isLoading)
      return Array.from({ length: 6 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="company_listing isotope-item is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="row">
                <div className="col-md-9">
                  <div className="company_info">
                    <figure className="image-loading"></figure>
                    <h3>&nbsp;</h3>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="float-lg-end text-center">
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })
    if (!data || !data?.length)
      return (
        <div className="full-width-auto">
          <div className="text-center">
            Not found any product in this category!
          </div>
        </div>
      )

    return data.map((product: Product) => (
      <ProductItem
        name={product?.name}
        avatar={product?.avatar}
        bio={product.bio}
        key={product._id}
        slug={product?.slug}
        review_summary={product?.review_summary}
      />
    ))
  }, [data, isLoading])

  if (!categoryId)
    return (
      <div className="full-width-auto">
        <div className="text-center">Category id is not exist!</div>
      </div>
    )

  return (
    <>
      <div className="margin_60_35 container">
        <div className="isotope-wrapper">{companies}</div>
        {/* <p className="text-center">
          <a href="#0" className="btn_1 add_top_15 rounded">
            Load more
          </a>
        </p> */}
      </div>
    </>
  )
}

export default CategoryCompanies
