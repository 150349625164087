import React from 'react'
import { Link } from 'react-router-dom'

import type { ICategoryItem } from './type'
import './style.css'

const CategoryItem: React.FC<ICategoryItem> = ({
  link,
  image,
  name,
  result,
  review,
  style,
}) => {
  if (style === 1) {
    const onMouseEnter = (e: any) => {
      const imgElement = e.target.querySelector('img')
      if (imgElement) imgElement.classList.toggle('rotate-x')
    }

    return (
      <Link to={link} className="box_cat_home" onMouseEnter={onMouseEnter}>
        {image && <img src={image} width={65} height={65} alt={name} />}
        <h3>{name}</h3>
        <ul className="clearfix">
          <li>
            <strong>{result ?? 0}</strong> Results
          </li>
          <li>
            <strong>{review ?? 0}</strong>
            <i className="icon-comment" />
          </li>
        </ul>
      </Link>
    )
  }

  return (
    <Link to={link} className="grid_item">
      <figure>
        {image && <img src={image} alt={name} />}
        <div className="info">
          <small>{result ?? 0} Results</small>
          <em>
            <i className="icon-comment" /> {review ?? 0} Reviews
          </em>
          <h3>{name}</h3>
        </div>
      </figure>
    </Link>
  )
}

export default CategoryItem
