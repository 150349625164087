/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { useAuth } from '../../hooks/useAuth'
import { useGetProductByIdQuery } from '../../services/product'
import {
  useAddReviewMutation,
  useGetReviewsByCompanyIdQuery,
} from '../../services/review'
import { timeStampToDate } from '../../utils/helpers'
import Checkbox from '../checkbox'
import ReviewItem from '../review-item'
import './style.css'

const WriteReview: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (!auth || !auth.user || auth.user === null) {
      navigate('/login')
    }
  }, [auth])

  const { slug } = useParams()
  const companyId = slug ? slug.split('-').pop() : ''
  const { data, isLoading } = useGetProductByIdQuery(companyId as string, {
    skip: !companyId,
  })

  const { data: reviewData } = useGetReviewsByCompanyIdQuery(
    companyId as string,
    {
      skip: !companyId,
    }
  )

  const [addReviewMutation] = useAddReviewMutation()

  const WriteReviewSchema = Yup.object().shape({
    content: Yup.string().required('Review content is required'),
    rating: Yup.number()
      .integer()
      .min(1)
      .max(5)
      .required('Rating should be greater than 0'),
    term_agree: Yup.bool().oneOf(
      [true],
      'The terms and conditions must be accepted.'
    ),
    title: Yup.string().required('Review title is required'),
  })

  const formik = useFormik({
    initialValues: {
      content: '',
      rating: 0,
      term_agree: false,
      title: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (companyId) {
        try {
          const sanitizeValues = {
            company_id: companyId,
            content: values.content,
            rating: Number(values.rating),
            title: values.title,
          }
          await addReviewMutation(sanitizeValues).unwrap()
          setSubmitting(false)
          setIsSubmitted(true)
        } catch (err: any) {
          toast.error(err?.data?.message, {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
          setSubmitting(false)
        }
      }
    },
    validationSchema: WriteReviewSchema,
  })

  if (isLoading)
    return (
      <div className="margin_60_35 is-loading-skeleton container">
        <div className="row">
          <div className="col-lg-8">
            <h4>&nbsp;</h4>
            <h4>&nbsp;</h4>
            <div className="write-review-content-loading"></div>
          </div>
          <div className="col-lg-4">
            <h4>&nbsp;</h4>
            <div className="sidebar-loading"></div>
          </div>
        </div>
      </div>
    )
  if (!companyId || (!isLoading && !data))
    return (
      <div className="full-width-auto">
        <div className="text-center">
          Product is not exist to write a review!
        </div>
      </div>
    )

  const reviews = () => {
    if (!reviewData || !reviewData.length) return <div>Not found review!</div>
    return reviewData.map((review) => (
      <ReviewItem
        key={review._id}
        image={review?.owner_ref?.avatar || '/images/avatar4.jpeg'}
        author={`${review?.owner_ref?.first_name} ${review?.owner_ref?.last_name}`}
        description={review.content || ''}
        published={timeStampToDate(review.created_at)}
        title={review.title}
        name={review.category_ref?.name || ''}
        link={`/product/${review?.company_ref?.slug}`}
        rating={review.rating}
        style={1}
      />
    ))
  }

  if (isSubmitted) {
    return (
      <>
        <div className="margin_60 mt-58 container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div id="confirm">
                <div className="icon icon--order-success svg add_bottom_15">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={72}
                    height={72}
                  >
                    <g fill="none" stroke="#8EC343" strokeWidth={2}>
                      <circle
                        cx={36}
                        cy={36}
                        r={35}
                        style={{
                          strokeDasharray: '240px, 240px',
                          strokeDashoffset: 480,
                        }}
                      />
                      <path
                        d="M17.417,37.778l9.93,9.909l25.444-25.393"
                        style={{
                          strokeDasharray: '50px, 50px',
                          strokeDashoffset: 0,
                        }}
                      />
                    </g>
                  </svg>
                </div>
                <h2>Review submitted!</h2>
                <p>Sit an meis aliquam, cetero inermis.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg_color_1">
          <div className="margin_60_35 container">
            <div className="row">
              <div className="col-lg-4">
                <a href="#0" className="boxed_list">
                  <i className="pe-7s-help2" />
                  <h4>Need Help?</h4>
                  <p>
                    Cum appareat maiestatis interpretaris et, et sit epicurei
                    tractatos constituam ad mel.
                  </p>
                </a>
              </div>
              <div className="col-lg-4">
                <a href="#0" className="boxed_list">
                  <i className="pe-7s-flag" />
                  <h4>Report Abuse</h4>
                  <p>
                    Cum appareat maiestatis interpretaris et, et sit epicurei
                    tractatos constituam ad mel.
                  </p>
                </a>
              </div>
              <div className="col-lg-4">
                <a href="#0" className="boxed_list">
                  <i className="pe-7s-note2" />
                  <h4>Cancel Policy</h4>
                  <p>
                    Cum appareat maiestatis interpretaris et, et sit epicurei
                    tractatos constituam ad mel.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="margin_60_35 container">
      <div className="row">
        <div className="col-lg-8">
          <form
            className="box_general write_review"
            onSubmit={formik.handleSubmit}
          >
            <h1>Write a review of {data?.name}</h1>
            <div className="rating_submit">
              <div className="form-group">
                <label className="d-block">Overall rating</label>
                <span className="rating">
                  <input
                    type="radio"
                    className="rating-input"
                    id="5_star"
                    name="rating"
                    value={5}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="5_star" className="rating-star">
                    &nbsp;
                  </label>
                  <input
                    type="radio"
                    className="rating-input"
                    id="4_star"
                    name="rating"
                    value={4}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="4_star" className="rating-star">
                    &nbsp;
                  </label>
                  <input
                    type="radio"
                    className="rating-input"
                    id="3_star"
                    name="rating"
                    value={3}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="3_star" className="rating-star">
                    &nbsp;
                  </label>
                  <input
                    type="radio"
                    className="rating-input"
                    id="2_star"
                    name="rating"
                    value={2}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="2_star" className="rating-star">
                    &nbsp;
                  </label>
                  <input
                    type="radio"
                    className="rating-input"
                    id="1_star"
                    name="rating"
                    value={1}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="1_star" className="rating-star">
                    &nbsp;
                  </label>
                </span>
              </div>
              {formik.touched.rating && formik.errors.rating ? (
                <div className="invalid-feedback-rv">
                  {formik.errors.rating}
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="title">Title of your review</label>
              <input
                className="form-control"
                type="text"
                placeholder="If you could say it in one sentence, what would you say?"
                id="title"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="invalid-feedback-rv">{formik.errors.title}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="content">Your review</label>
              <textarea
                className="form-control"
                style={{ height: 180 }}
                placeholder="Write your review to help others learn about this online business"
                name="content"
                id="content"
                onChange={formik.handleChange}
                value={formik.values.content}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="invalid-feedback-rv">
                  {formik.errors.content}
                </div>
              ) : null}
            </div>
            {/* <div className="form-group">
              <label htmlFor="fileupload">Add your photo (optional)</label>
              <div className="fileupload">
                <input
                  type="file"
                  name="fileupload"
                  accept="image/*"
                  id="fileupload"
                />
              </div>
            </div> */}
            <div className="form-group">
              <div className="checkboxes float-start add_bottom_15 add_top_15">
                <Checkbox
                  label={`Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod
                  scaevola sea. Et nec tantas accusamus salutatus, sit commodo
                  veritus te, erat legere fabulas has ut. Rebum laudem cum ea,
                  ius essent fuisset ut. Viderer petentium cu his.`}
                  name="term_agree"
                  onChange={formik.handleChange}
                />
                {formik.touched.term_agree && formik.errors.term_agree ? (
                  <div className="invalid-feedback-rv">
                    {formik.errors.term_agree}
                  </div>
                ) : null}
              </div>
            </div>
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="btn_1"
            >
              Submit review
            </button>
          </form>
        </div>
        <div className="col-lg-4">
          <div className="latest_review">
            <h4>
              Recent reviews
              <br />
              for {data?.name}
            </h4>
            {reviews()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WriteReview
