import React from 'react'
import { Link } from 'react-router-dom'

import { useGetPostsListQuery } from '../../services/post'
import './style.css'

const Help: React.FC = () => {
  const { data, isLoading } = useGetPostsListQuery({ limit: 100, page: 1 })

  const listBlogs = () => {
    if (isLoading) return null
    if (!data || !data.length) return null
    const html = data.map((post) => (
      <li key={post._id}>
        <Link to={`/post/${post?.slug}`}>
          <i className="icon_documents_alt" />
          {post.name}
        </Link>
      </li>
    ))
    return <ul>{html}</ul>
  }

  return (
    <>
      <section className="hero_single general">
        <div className="wrapper">
          <div className="container">
            <i className="pe-7s-help2" />
            <h1>Customer Advocates Help Center</h1>
            <form>
              <div id="custom-search-input">
                <div className="input-group">
                  <input
                    type="text"
                    className="search-query"
                    placeholder="Ask a question..."
                  />
                  <input
                    type="submit"
                    className="btn_search"
                    defaultValue="Search"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <div className="margin_60_35 container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <a className="box_topic" href="#0">
              <span>
                <i className="pe-7s-wallet" />
              </span>
              <h3>Payments</h3>
              <p>
                Id mea congue dictas, nec et summo mazim impedit. Vim te audiam
                impetus interpretaris.
              </p>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="box_topic" href="#0">
              <i className="pe-7s-users" />
              <h3>Account</h3>
              <p>
                Id mea congue dictas, nec et summo mazim impedit. Vim te audiam
                impetus interpretaris.
              </p>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="box_topic" href="#0">
              <i className="pe-7s-help2" />
              <h3>General help</h3>
              <p>
                Id mea congue dictas, nec et summo mazim impedit. Vim te audiam
                impetus interpretaris.
              </p>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="box_topic" href="#0">
              <i className="pe-7s-global" />
              <h3>International</h3>
              <p>
                Id mea congue dictas, nec et summo mazim impedit. Vim te audiam
                impetus interpretaris.
              </p>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="box_topic" href="#0">
              <i className="pe-7s-note2" />
              <h3>Cancellation</h3>
              <p>
                Id mea congue dictas, nec et summo mazim impedit. Vim te audiam
                impetus interpretaris.
              </p>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="box_topic" href="#0">
              <i className="pe-7s-comment" />
              <h3>Reviews</h3>
              <p>
                Id mea congue dictas, nec et summo mazim impedit. Vim te audiam
                impetus interpretaris.
              </p>
            </a>
          </div>
        </div>
      </div>

      <div className="bg_color_1">
        <div className="margin_60_35 container">
          <div className="main_title_3">
            <span>
              <em />
            </span>
            <h2>Popular articles</h2>
            <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
          </div>
          <div className="list_articles add_bottom_30 clearfix">
            {listBlogs()}
          </div>
          {/* /list_articles */}
        </div>
        {/* /container */}
      </div>
    </>
  )
}

export default Help
