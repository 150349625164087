import React from 'react'
import { Link } from 'react-router-dom'

import { ratingHtml } from '../../utils/helpers'

import type { IReviewItem } from './type'

import './style.css'

const ReviewItem: React.FC<IReviewItem> = ({
  image,
  author,
  title,
  description,
  published,
  name,
  link,
  style,
  rating,
  product,
}) => {
  const ratingNumber = rating?.toLocaleString('en', {
    minimumFractionDigits: 2,
    useGrouping: false,
  })

  const readReview = link ? (
    <Link to={link} className="btn_1 small">
      Read review
    </Link>
  ) : (
    <span className="btn_1 small">Read review</span>
  )

  const reviewAuthor = link ? (
    <Link to={link}>{product}</Link>
  ) : (
    <span>{product}</span>
  )

  if (style === 1) {
    return (
      <div className="review_listing">
        <div className="clearfix add_bottom_10">
          <figure>{image && <img src={image} alt={''} />}</figure>
          <span className="rating">
            {ratingHtml(rating)}
            <em>{ratingNumber}/5.00</em>
          </span>
          <small>{name}</small>
        </div>
        <h3>
          <strong>{author}</strong>
        </h3>
        <h4>"{title}"</h4>
        <p>{description}</p>
        <ul className="clearfix">
          <li>
            <small>{published}</small>
          </li>
          <li>{readReview}</li>
        </ul>
      </div>
    )
  }

  if (style === 3) {
    return (
      <div className="review_listing">
        <div className="clearfix add_bottom_15">
          <figure>{image && <img src={image} alt={''} />}</figure>
          <span className="rating">
            {ratingHtml(rating)}
            <em>{ratingNumber}/5.00</em>
          </span>
          <small>{name}</small>
        </div>
        <h3>
          <strong>{author}</strong> reviewed {reviewAuthor}
        </h3>
        <h4>"{title}"</h4>
        <p>{description}</p>
        <ul className="clearfix">
          <li>
            <small>Published: {published}</small>
          </li>
          <li>{readReview}</li>
        </ul>
      </div>
    )
  }

  return (
    <div className="item">
      <div className="review_listing">
        <div className="clearfix">
          <figure>{image && <img src={image} alt={''} />}</figure>
          <span className="rating">
            {ratingHtml(rating)}
            <em>{ratingNumber}/5.00</em>
          </span>
          <small>{name}</small>
        </div>
        <h3>
          <strong>{author}</strong> reviewed {reviewAuthor}
        </h3>
        <h4>"{title}"</h4>
        <p>{description}</p>
        <ul className="clearfix">
          <li>
            <small>Published: {published}</small>
          </li>
          <li>{readReview}</li>
        </ul>
      </div>
    </div>
  )
}

export default ReviewItem
