import React from 'react'
import { Link } from 'react-router-dom'

import { HUB_BASE_URL } from '../../constants/api'
import './style.css'

interface ICallToAction {
  style?: number
}

const CallToAction: React.FC<ICallToAction> = ({ style }) => {
  if (style === 2) {
    return (
      <div className="call_section_2">
        <div className="wrapper">
          <div className="container">
            <h3>
              Get started now with Customer Advocates...improve your business.
            </h3>
            <Link className="btn_1 add_top_10 wow bounceIn" to={'/contacts'}>
              Connect with us
            </Link>
          </div>
        </div>
      </div>
    )
  } else if (style === 3) {
    return (
      <div className="call_section_3">
        <div className="wrapper">
          <div className="clearfix container">
            <div className="col-lg-5 col-md-7 float-end">
              <h3>Let's Help You</h3>
              <p>
                Customer Advocates is the modern Customer Advocacy platform open
                to everyone. We help you choose the right products, get the best
                product experiences, and help product companies up their game.
                Our mission is to unlock real business value by bringing
                customers and product companies together and create the best
                experiences for everyone.
              </p>
              <p>
                <Link
                  className="btn_1 add_top_10 wow bounceIn"
                  data-wow-delay="0.5s"
                  to={'/contacts'}
                >
                  Connect with us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="call_section">
      <div className="margin_80_55 container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <img alt="" className="img-fluid" src="images/graphic_home_1.svg" />
          </div>
          <div className="col-xl-5 col-lg-6 pt-lg-5">
            <h3>Let's Help You</h3>
            <p>
              Customer Advocates is the modern Customer Advocacy platform open
              to everyone. We help you choose the right products, get the best
              product experiences, and help product companies up their game. Our
              mission is to unlock real business value by bringing customers and
              product companies together and create the best experiences for
              everyone.
            </p>
            <p>
              <Link
                className="btn_1 add_top_10 wow bounceIn"
                data-wow-delay="0.5s"
                to={'/contacts'}
              >
                Connect with us
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
