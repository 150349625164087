import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth } from '../../hooks/useAuth'
import { useAddQuoteMutation } from '../../services/quote'
import type { IRequestQuote } from '../../services/quote/types'
import { useGetQuoteByCompanyIdQuery } from '../../services/survey'

import SurveyUI from './surveyUI'
import 'survey-core/defaultV2.min.css'

import './style.css'

const SurveyComponent = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { slug } = useParams()
  const productId = slug ? slug.split('-').pop() : ''
  const { data, isLoading } = useGetQuoteByCompanyIdQuery(productId as string, {
    skip: !productId,
  })
  const [addQuoteMutation] = useAddQuoteMutation()

  useEffect(() => {
    if (!auth || !auth.user || auth.user === null) {
      navigate('/login')
    }
  }, [auth])

  const quoteId = useMemo(() => {
    return data?._id || ''
  }, [data])

  const handleSubmitSurvey = useCallback(
    async (values: string) => {
      try {
        const sanitizeValues: IRequestQuote = {
          company_id: productId as string,
          questionAndAnswer: values,
          quote_id: quoteId,
        }
        await addQuoteMutation(sanitizeValues).unwrap()
        toast.success('Create quote successful', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
      } catch (error: any) {
        toast.error(error?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
      }
    },
    [quoteId, productId]
  )

  const surveyUI = useMemo(() => {
    if (data?.question) {
      return (
        <SurveyUI
          question={data?.question}
          handleSubmitSurvey={handleSubmitSurvey}
        />
      )
    }
    return null
  }, [data?.question])

  if (isLoading)
    return (
      <div className="margin_60_35 is-loading-skeleton container">
        <div className="row">
          <div className="col-lg-8">
            <h4>&nbsp;</h4>
            <h4>&nbsp;</h4>
            <div className="write-review-content-loading"></div>
          </div>
          <div className="col-lg-4">
            <h4>&nbsp;</h4>
            <div className="sidebar-loading"></div>
          </div>
        </div>
      </div>
    )
  if (!productId || (!isLoading && !data))
    return (
      <div className="full-width-auto">
        <div className="text-center">
          Product is not exist to submit a quote!
        </div>
      </div>
    )

  return (
    <>
      <div className="subheader" />
      <div className="wrapper_in">
        <div className="container-fluid">
          <div className="row">
            <aside className="col-xl-3 col-lg-4">
              <h2>Request a Quote and Compare prices!</h2>
              <p className="lead">
                An mei sadipscing dissentiet, eos ea partem viderer facilisi.
              </p>
              <ul className="list_ok">
                <li>
                  Delicata persecuti ei nec, et his minim omnium, aperiam
                  placerat ea vis.
                </li>
                <li>
                  Suavitate vituperatoribus pro ad, cum in quis propriae
                  abhorreant.
                </li>
                <li>
                  Aperiri deterruisset ei mea, sed cu laudem intellegat, eu
                  mutat iuvaret voluptatum mei.
                </li>
              </ul>
            </aside>
            <div className="col-xl-9 col-lg-8">
              <div id="wizard_container">{surveyUI}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SurveyComponent
