import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { ILoginResponse, IUser } from '../../services/user/types'
import type { RootState } from '../../store'

type AuthState = {
  user: IUser | null
  accessToken: string | null
}

const initialState: AuthState = {
  accessToken: null,
  user: null,
}

const slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logout: () => {
      localStorage.removeItem('accessToken')
      return initialState
    },
    setCredentials: (
      state,
      { payload: { user, accessToken } }: PayloadAction<ILoginResponse>
    ) => {
      state.user = user
      state.accessToken = accessToken
    },
  },
})

export const { setCredentials, logout } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
