import React from 'react'
import { Link } from 'react-router-dom'

import './style.css'

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#collapse_ft_1"
              aria-expanded="false"
              aria-controls="collapse_ft_1"
              className="collapse_bt_mobile"
            >
              <h3>Quick Links</h3>
              <div className="circle-plus closed">
                <div className="horizontal" />
                <div className="vertical" />
              </div>
            </a>
            <div className="show collapse" id="collapse_ft_1">
              <ul className="links">
                <li>
                  <Link to={'/about-us'}>About us</Link>
                </li>
                <li>
                  <Link to={'/faq'}>Faq</Link>
                </li>
                <li>
                  <Link to={'/help'}>Help</Link>
                </li>
                <li>
                  <Link to={'/user-dashboard'}>My account</Link>
                </li>
                <li>
                  <Link to={'/register'}>Create account</Link>
                </li>
                <li>
                  <Link to={'/contacts'}>Contacts</Link>
                </li>
                <li>
                  <Link to={'/blog'}>Blog</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#collapse_ft_2"
              aria-expanded="false"
              aria-controls="collapse_ft_2"
              className="collapse_bt_mobile"
            >
              <h3>Categories</h3>
              <div className="circle-plus closed">
                <div className="horizontal" />
                <div className="vertical" />
              </div>
            </a>
            <div className="show collapse" id="collapse_ft_2">
              <ul className="links">
                <li>
                  <Link to="/">Cloud Computing Platform</Link>
                </li>
                <li>
                  <Link to="/">Customer Relationship Management</Link>
                </li>
                <li>
                  <Link to="/">Customer Contact Management </Link>
                </li>
                <li>
                  <Link to="/">Customer Service Management </Link>
                </li>
                <li>
                  <Link to="/">eCommerce Sales Management</Link>
                </li>
                <li>
                  <a href="#0">View all</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#collapse_ft_3"
              aria-expanded="false"
              aria-controls="collapse_ft_3"
              className="collapse_bt_mobile"
            >
              <h3>Contacts</h3>
              <div className="circle-plus closed">
                <div className="horizontal" />
                <div className="vertical" />
              </div>
            </a>
            <div className="show collapse" id="collapse_ft_3">
              <ul className="contacts">
                <li>
                  <i className="ti-home" />
                  Registered Office:
                  <br />
                  167-169 Great Portland Street
                  <br /> London W1W 5PF
                  <br /> United Kingdom
                </li>
                {/* <li>
                  <i className="ti-headphone-alt" />
                  +61 23 8093 3400
                </li> */}
                <li>
                  <i className="ti-email" />
                  <a
                    href={`mailto:hello@customer365.io`}
                  >{`hello@hello@customer365.io`}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            {/* <a
              data-bs-toggle="collapse"
              data-bs-target="#collapse_ft_4"
              aria-expanded="false"
              aria-controls="collapse_ft_4"
              className="collapse_bt_mobile"
            >
              <div className="circle-plus closed">
                <div className="horizontal" />
                <div className="vertical" />
              </div>
              <h3>Keep in touch</h3>
            </a> */}
            <div className="show collapse" id="collapse_ft_4">
              <img
                src="/images/methodobjects.png"
                // width="140"
                height="35"
                alt=""
                className="logo_sticky"
              />
              <div className="follow_us">
                <h5>Follow Us</h5>
                <ul>
                  <li>
                    <a href="#0">
                      <i className="ti-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="ti-twitter-alt" />
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="ti-google" />
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="ti-pinterest" />
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="ti-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-6">
            <ul id="footer-selector">
              <li>
                <div className="styled-select" id="lang-selector">
                  <select>
                    <option value="English">English</option>
                    <option value="French">French</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Russian">Russian</option>
                  </select>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul id="additional_links">
              <li>
                <Link to="/termsofuse">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <span>© 2021 Customer Advocates</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
