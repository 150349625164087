import React from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'

import 'survey-core/defaultV2.min.css'

interface ISurveyUI {
  question: string
  handleSubmitSurvey: (values: any) => void
}

function SurveyUI({ question, handleSubmitSurvey }: ISurveyUI) {
  const survey = new Model(question)
  survey.onComplete.add((survey) => {
    // console.log(JSON.stringify(survey.data, null, 3))
    /* const resultData = []
    for (const key in survey.data) {
      const question = survey.getQuestionByName(key)
      if (!!question) {
        const item = {
          answer: question.displayValue,
          question: question.title,
        }
        resultData.push(item)
      }
    }
    if (resultData.length) {
      handleSubmitSurvey(resultData)
    } */
    handleSubmitSurvey(JSON.stringify(survey.data, null, 3))
  })

  return <Survey model={survey} />
}

export default SurveyUI
