import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Icon404 from '../icons/404'

import './style.css'

const ErrorView: React.FC = () => {
  const navigate = useNavigate()

  const searchField = useRef<HTMLInputElement>(null)
  const handleSubmitSearch = (event: any) => {
    event.preventDefault()
    const search = searchField.current?.value
    navigate(`/listing-reviews?search=${search}&category=`)
  }

  return (
    <section className="hero_single general">
      <div className="wrapper">
        <div className="container">
          <form onSubmit={handleSubmitSearch}>
            <Icon404 /* className="img-fluid" */ />
            <div id="custom-search-input">
              <div className="input-group">
                <input
                  type="text"
                  className="search-query"
                  placeholder="Search pages..."
                  name="search"
                  ref={searchField}
                />
                <input
                  type="submit"
                  className="btn_search"
                  defaultValue="Search"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ErrorView
