import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './style.css'
import { useGetPostsListQuery } from '../../services/post'
import { truncateAndDecodeHTML } from '../../utils/helpers'

const Contacts: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(1)

  const { data, isLoading } = useGetPostsListQuery({ limit: 4, page: 1 })

  return (
    <>
      <section className="hero_single general">
        <div className="wrapper">
          <div className="container">
            <h1>Get in Touch with Customer Advocates</h1>
            <p>
              Customer Advocates helps grow your business using customer reviews
            </p>
          </div>
        </div>
      </section>
      <div className="bg_color_1">
        <div className="margin_tabs container">
          <div id="tabs" className="tabs">
            <nav>
              <ul>
                <li className={activeTab === 1 ? 'tab-current' : ''}>
                  <span onClick={() => setActiveTab(1)} aria-hidden="true">
                    <i className="pe-7s-help1" />
                    Questions<em>Omnis justo gloriatur et sit</em>
                  </span>
                </li>
                <li className={activeTab === 2 ? 'tab-current' : ''}>
                  <span onClick={() => setActiveTab(2)} aria-hidden="true">
                    <i className="pe-7s-help2" />
                    Support<em>Quo corrumpit euripidis</em>
                  </span>
                </li>
              </ul>
            </nav>
            <div className="content">
              <section
                id="section-1"
                className={activeTab === 1 ? 'content-current' : ''}
              >
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div id="message-contact" />
                    <form id="contactform" autoComplete="off">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group required">
                            <input
                              className="form-control"
                              type="text"
                              id="name_contact"
                              name="name_contact"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group required">
                            <input
                              className="form-control"
                              type="text"
                              id="lastname_contact"
                              name="lastname_contact"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      {/* /row */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group required">
                            <input
                              className="form-control"
                              type="email"
                              id="email_contact"
                              name="email_contact"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group required">
                            <input
                              className="form-control"
                              type="text"
                              id="phone_contact"
                              name="phone_contact"
                              placeholder="Telephone"
                            />
                          </div>
                        </div>
                      </div>
                      {/* /row */}
                      <div className="form-group required">
                        <textarea
                          className="form-control"
                          id="message_contact"
                          name="message_contact"
                          style={{ height: 150 }}
                          placeholder="Message"
                          defaultValue={''}
                        />
                      </div>
                      <div className="form-group required">
                        <input
                          className="form-control"
                          type="text"
                          id="verify_contact"
                          name="verify_contact"
                          placeholder="Are you human? 3 + 1 ="
                        />
                      </div>
                      <div className="form-group add_top_30 text-center">
                        <input
                          type="submit"
                          defaultValue="Submit"
                          className="btn_1 rounded"
                          id="submit-contact"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                {/* /row */}
              </section>
              {/* /section */}
              <section
                id="section-2"
                className={activeTab === 2 ? 'content-current' : ''}
              >
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <Link to={'/help'} className="btn_support">
                      Please first visit our Support Center!
                    </Link>
                    <div id="message-support" />
                    <form id="support" autoComplete="off">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group required">
                            <input
                              className="form-control"
                              type="text"
                              id="name_support"
                              name="name_support"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group required">
                            <input
                              className="form-control"
                              type="email"
                              id="email_support"
                              name="email_support"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                      </div>
                      {/* /row */}
                      <div className="form-group required">
                        <textarea
                          className="form-control"
                          id="message_support"
                          name="message_support"
                          style={{ height: 150 }}
                          placeholder="Support request"
                          defaultValue={''}
                        />
                      </div>
                      <div className="form-group required">
                        <input
                          className="form-control"
                          type="text"
                          id="verify_support"
                          name="verify_support"
                          placeholder="Are you human? 3 + 1 ="
                        />
                      </div>
                      <div className="form-group add_top_30 text-center">
                        <input
                          type="submit"
                          defaultValue="Submit"
                          className="btn_1 rounded"
                          id="submit-support"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                {/* /row */}
              </section>
              {/* /section */}
            </div>
            {/* /content */}
          </div>
          {/* /tabs */}
        </div>
        {/* /container */}
      </div>
      <div className="margin_60_35 container">
        {data?.reduce((rows: JSX.Element[], post, index) => {
          if (index % 2 === 0) {
            rows.push(
              <div className="row" key={index}>
                <div className="col-md-6">
                  <div className="box_faq">
                    <i className="icon_info_alt" />
                    <h4>{post.name}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncateAndDecodeHTML(post?.content, 100),
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          } else {
            rows[rows.length - 1].props.children.push(
              <div className="col-md-6" key={index}>
                <div className="box_faq">
                  <i className="icon_info_alt" />
                  <h4>{post.name}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: truncateAndDecodeHTML(post?.content, 100),
                    }}
                  />
                </div>
              </div>
            )
          }
          return rows
        }, [])}
      </div>
    </>
  )
}

export default Contacts
