import React from 'react'

import { ratingHtml } from '../../utils/helpers'
import type { IReviewItem } from '../review-item/type'

import './style.css'

const ReviewCard: React.FC<IReviewItem> = ({
  image,
  author,
  published,
  title,
  description,
  style,
  name,
  rating,
  onDelete,
  onEdit,
}) => {
  const ratingNumber = rating?.toLocaleString('en', {
    minimumFractionDigits: 2,
    useGrouping: false,
  })
  if (style === 2) {
    return (
      <div className="review_card">
        <div className="row">
          <div className="col-md-2 user_info">
            <figure>{image && <img src={image} alt={author} />}</figure>
            <h5>Review: "{name}"</h5>
          </div>
          <div className="col-md-10 review_content">
            <div className="clearfix add_bottom_15">
              <span className="rating">
                {ratingHtml(rating)}
                <em>{ratingNumber}/5.00</em>
              </span>
              <em>Published: {published}</em>
            </div>
            <h4>"{title}"</h4>
            <p>{description}</p>
            <ul>
              <li>
                <button className="btn_delete" onClick={onDelete}>
                  <i className="icon-trash" />
                  Delete
                </button>
              </li>
              <li>
                <button onClick={onEdit}>
                  <i className="icon-edit-3" /> Edit
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="review_card">
      <div className="row">
        <div className="col-md-2 user_info">
          <figure>{image && <img src={image} alt={author} />}</figure>
          <h5>{author}</h5>
        </div>
        <div className="col-md-10 review_content">
          <div className="clearfix add_bottom_15">
            <span className="rating">
              {ratingHtml(rating)}
              <em>{ratingNumber}/5.00</em>
            </span>
            <em>Published {published}</em>
          </div>
          <h4>"{title}"</h4>
          <p>{description}</p>
          <ul>
            <li>
              <a href="#0">
                <i className="icon_like_alt" />
                <span>Useful</span>
              </a>
            </li>
            <li>
              <a href="#0">
                <i className="icon_dislike_alt" />
                <span>Not useful</span>
              </a>
            </li>
            <li>
              <span>Share</span>{' '}
              <a href="#0">
                <i className="ti-facebook" />
              </a>{' '}
              <a href="#0">
                <i className="ti-twitter-alt" />
              </a>{' '}
              <a href="#0">
                <i className="ti-google" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="row reply">
        <div className="col-md-2 user_info">
          <figure>
            <img src="img/avatar.jpg" alt="" />
          </figure>
        </div>
        <div className="col-md-10">
          <div className="review_content">
            <strong>Reply from Good Electronics</strong>
            <em>Published 3 minutes ago</em>
            <p>
              <br />
              Hi Monika,
              <br />
              <br />
              Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod
              scaevola sea. Et nec tantas accusamus salutatus, sit commodo
              veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius
              essent fuisset ut. Viderer petentium cu his. Tollit molestie
              suscipiantur his et.
              <br />
              <br />
              Thanks
            </p>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default ReviewCard
