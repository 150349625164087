import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.css'
import CallToAction from '../call-to-action'

const fakeBrands = [
  {
    image: '/images/brands/1_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/2_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/3_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/4_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/5_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/6_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/7_c.jpeg',
    link: '',
  },
  {
    image: '/images/brands/8_c.jpeg',
    link: '',
  },
]

const Companies: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    slidesToShow: 7,
    speed: 500,
  }

  const brands = () => {
    if (!fakeBrands.length) return null

    return fakeBrands.map((brand, index) => (
      <div key={index} className="item">
        <Link to={brand.link}>
          <img src={brand.image} alt="" />
        </Link>
      </div>
    ))
  }

  return (
    <>
      <section className="hero_single version_company">
        <div className="wrapper">
          <div className="container">
            <h3>
              The Power
              <br />
              of your most passionate customers
            </h3>
            <p>
              Customer Advocates helps grow your business using customer
              reviews!
            </p>
          </div>
        </div>
      </section>
      <div className="bg_color_1">
        <div className="margin_60_35 container">
          <div className="row">
            <div className="col-lg-4">
              <div className="box_feat">
                <i className="pe-7s-speaker" />
                <h3>
                  <strong>30</strong> thousand<em>reviews seen every month</em>
                </h3>
                <p>Over 30 thousand review impressions every month</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box_feat">
                <i className="pe-7s-flag" />
                <h3>
                  <strong>5</strong> thousand<em>real reviews per month</em>
                </h3>
                <p>Over 5 thousand reviews posted every month</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box_feat">
                <i className="pe-7s-rocket" />
                <h3>
                  <strong>1</strong> thousand<em>have a great return</em>
                </h3>
                <p>Over 1 thousand companies increase their business</p>
              </div>
            </div>
          </div>
          <div className="margin_60 brands">
            <h5 className="add_bottom_30 text-center">
              More than 1000 companies use Customer Advocates!
            </h5>
            <Slider {...settings}>{brands()}</Slider>
          </div>
        </div>
      </div>
      <div className="feat_blocks">
        <div className="container-fluid h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-md-6 p-0">
              <div className="block_1">
                <img
                  src="/images/company_info_graphic_1.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="block_2">
                <h3>Increase conversions with the power of your customers</h3>
                <p>
                  Mucius doctus constituto pri at, ne cetero postulant pro. At
                  vix utinam corpora, ea oblique moderatius usu. Vix id viris
                  consul honestatis, an constituto deterruisset consectetuer
                  pro.
                </p>
                <Link to="/contacts" className="btn_1">
                  Connect with us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg_color_1">
        <div className="margin_60_35 container">
          <div className="main_title_2">
            <h2>Features</h2>
            <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="box_feat_2">
                <h3>
                  <i className="pe-7s-graph3" />
                  Analytics Tools
                </h3>
                <p>
                  <strong>Mucius doctus constituto pri at.</strong> At vix
                  utinam corpora, ea oblique moderatius usu. Vix id viris consul
                  honestatis, an constituto deterruisset consectetuer pro quo
                  corrumpit euripidis.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box_feat_2">
                <h3>
                  <i className="pe-7s-share" />
                  Social Integration
                </h3>
                <p>
                  <strong>Mucius doctus constituto pri at.</strong> At vix
                  utinam corpora, ea oblique moderatius usu. Vix id viris consul
                  honestatis, an constituto deterruisset consectetuer pro quo
                  corrumpit euripidis.
                </p>
              </div>
            </div>
          </div>
          {/* /row */}
          <div className="row">
            <div className="col-md-6">
              <div className="box_feat_2">
                <h3>
                  <i className="pe-7s-target" />
                  Targeted Consumers
                </h3>
                <p>
                  <strong>Mucius doctus constituto pri at.</strong> At vix
                  utinam corpora, ea oblique moderatius usu. Vix id viris consul
                  honestatis, an constituto deterruisset consectetuer pro quo
                  corrumpit euripidis.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box_feat_2">
                <h3>
                  <i className="pe-7s-help2" />
                  Awesome Support
                </h3>
                <p>
                  <strong>Mucius doctus constituto pri at.</strong> At vix
                  utinam corpora, ea oblique moderatius usu. Vix id viris consul
                  honestatis, an constituto deterruisset consectetuer pro quo
                  corrumpit euripidis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction style={2} />
    </>
  )
}

export default Companies
