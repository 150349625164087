import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { logout } from '../../features/auth/authSlice'
import { useAuth } from '../../hooks/useAuth'
import {
  useDeleteProfileMutation,
  useUpdateProfileMutation,
} from '../../services/user'
import UserSummary from '../user-summary'

import './style.css'

const UserSettings: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (!auth || !auth.user) {
    navigate('/login')
  }
  const { user } = auth

  const [updateProfileMutation /* { isLoading, isError, error, isSuccess } */] =
    useUpdateProfileMutation()
  const [deleteProfileMutation /* { isLoading, isError, error, isSuccess } */] =
    useDeleteProfileMutation()

  const ProfileSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last name is required'),
  })

  const formik = useFormik({
    initialValues: {
      bio: user?.bio || '',
      city: user?.city || '',
      country: user?.country || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await updateProfileMutation(values)
        toast.success('Update profile successful', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      } catch (err: any) {
        toast.error(err?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: ProfileSchema,
  })

  const PasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    password1: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    password2: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password1'), null], 'Passwords must match'),
  })

  const formik2 = useFormik({
    initialValues: {
      password: '',
      password1: '',
      password2: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const sanitizeValues = {
          first_name: user?.first_name as string,
          last_name: user?.last_name as string,
          old_password: values.password,
          password: values.password1,
        }
        const result: any = await updateProfileMutation(sanitizeValues)
        if (result?.error) {
          toast.error(result?.error?.data?.message, {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.success('Update profile successful', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        }
        setSubmitting(false)
      } catch (err: any) {
        toast.error(err?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: PasswordSchema,
  })

  const onDeleteAccount = async () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure to delete account?') == true) {
      try {
        const result: any = await deleteProfileMutation(
          user?._id as string
        ).unwrap()
        if (!result?.success) {
          toast.error(result?.message, {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.success('Delete account successful', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
          dispatch(logout())
          navigate('/')
        }
      } catch (err: any) {
        toast.error(err?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
      }
    }
  }

  return (
    <>
      <UserSummary />
      <div className="margin_60_35 container">
        <div className="row">
          <div className="col-lg-8">
            <form className="settings_panel" onSubmit={formik.handleSubmit}>
              <h3>Personal settings</h3>
              <hr />
              <div className="form-group">
                <label htmlFor="bio">Edit Profile text</label>
                <textarea
                  className="form-control"
                  style={{ height: 180 }}
                  placeholder="Your profile"
                  id="bio"
                  onChange={formik.handleChange}
                  value={formik.values.bio}
                />
              </div>
              <div className="form-group">
                <label htmlFor="avatar">Edit Photo</label>
                <div className="fileupload">
                  <input
                    type="file"
                    id="avatar"
                    name="fileupload"
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Edit Email</label>
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  disabled
                  value={user?.email}
                />
              </div>
              <div className="form-group">
                <label htmlFor="first_name">Edit First name</label>
                <input
                  className="form-control"
                  type="text"
                  id="first_name"
                  placeholder="First Name"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="invalid-feedback-rv">
                    {formik.errors.first_name}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Edit Last name</label>
                <input
                  className="form-control"
                  type="text"
                  id="last_name"
                  placeholder="Last Name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="invalid-feedback-rv">
                    {formik.errors.last_name}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="city">Edit City</label>
                <input
                  className="form-control"
                  type="text"
                  id="city"
                  placeholder="City"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Edit Country</label>
                <input
                  className="form-control"
                  type="text"
                  id="country"
                  placeholder="Country"
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
              </div>
              <p className="text-end">
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn_1 small add_top_15"
                >
                  Save personal info
                </button>
              </p>
            </form>
            {/* /settings_panel */}
            <form className="settings_panel" onSubmit={formik2.handleSubmit}>
              <h3>Change password</h3>
              <hr />
              <div className="form-group">
                <label htmlFor="password">Current Password</label>
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  onChange={formik2.handleChange}
                  value={formik2.values.password}
                />
                {formik2.touched.password && formik2.errors.password ? (
                  <div className="invalid-feedback-rv">
                    {formik2.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password1">New Password</label>
                <input
                  className="form-control"
                  type="password"
                  id="password1"
                  onChange={formik2.handleChange}
                  value={formik2.values.password1}
                />
                {formik2.touched.password1 && formik2.errors.password1 ? (
                  <div className="invalid-feedback-rv">
                    {formik2.errors.password1}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password2">Confirm Password</label>
                <input
                  className="form-control"
                  type="password"
                  id="password2"
                  onChange={formik2.handleChange}
                  value={formik2.values.password2}
                />
                {formik2.touched.password2 && formik2.errors.password2 ? (
                  <div className="invalid-feedback-rv">
                    {formik2.errors.password2}
                  </div>
                ) : null}
              </div>
              <div id="pass-info" className="clearfix" />
              <p className="text-end">
                <button
                  type="submit"
                  disabled={formik2.isSubmitting}
                  className="btn_1 small"
                >
                  Save password
                </button>
              </p>
            </form>
            {/* /settings_panel */}
          </div>
          {/* /col */}
          <div className="col-lg-4" id="sidebar">
            <div className="box_general">
              <h5>Delete account</h5>
              <p>
                At nec senserit aliquando intellegat, et graece facilisis pro.
                Per in ridens sensibus interesset, eos ei nonumes incorrupte,
                iriure diceret an eos.
              </p>
              <button className="btn_1 small" onClick={onDeleteAccount}>
                Delete account
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserSettings
