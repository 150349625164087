import React, { useState } from 'react'

import './style.css'

const CookieBar: React.FC = () => {
  const [isHided, setIsHided] = useState<boolean>(false)

  const onHidden = () => {
    setIsHided(true)
  }
  if (isHided) return null

  return (
    <div id="cookie-bar" className="bottom fixed">
      <p>
        We use cookies to track usage and preferences.
        <button className="cb-enable" onClick={onHidden}>
          I accept
        </button>
        <button className="cb-policy">Privacy Policy</button>
      </p>
    </div>
  )
}

export default CookieBar
