import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { IResolveParams } from 'reactjs-social-login'
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'
import * as Yup from 'yup'

import { FB_APP_ID, GG_APP_ID } from '../../constants/social-apps-id'
import {
  useLoginSocialMutation,
  useRegisterMutation,
} from '../../services/user'
import { LoginType } from '../../services/user/types'
import './style.css'

const Register: React.FC = () => {
  const navigate = useNavigate()
  const [registerMutation /* { isLoading, isError, error, isSuccess } */] =
    useRegisterMutation()
  const [loginSocialMutation] = useLoginSocialMutation()

  useEffect(() => {
    document.body.classList.add('body_bg')
    return () => {
      document.body.classList.remove('body_bg')
    }
  })

  const SignUpSchema = Yup.object().shape({
    confirm_password: Yup.string()
      .label('confirm password')
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name is required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Last name is required'),
    password: Yup.string().required('Password is required'),
  })

  const formik = useFormik({
    initialValues: {
      confirm_password: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { confirm_password, ...rest } = values
        await registerMutation(rest).unwrap()
        toast.success('Register successful', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
        navigate('/login')
      } catch (err: any) {
        toast.error(err?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: SignUpSchema,
  })

  const REDIRECT_URI =
    'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login'
  const onLoginStart = useCallback(() => {}, [])

  const _handleSocialLogin = async (
    provider: LoginType.GOOGLE | LoginType.FACEBOOK,
    data: any
  ) => {
    try {
      const sanitizeValues = {
        avatar: '',
        email: data?.email,
        first_name: '',
        last_name: '',
        login_type: LoginType.GOOGLE,
        password: '',
      }
      if (provider === LoginType.GOOGLE) {
        sanitizeValues.avatar = data?.picture
        sanitizeValues.first_name = data?.given_name
        sanitizeValues.last_name = data?.family_name
        sanitizeValues.login_type = LoginType.GOOGLE
        sanitizeValues.password = data?.sub
      } else {
        sanitizeValues.first_name = data?.first_name
        sanitizeValues.first_name = data?.last_name
        sanitizeValues.avatar = data?.picture?.data?.url
        sanitizeValues.login_type = LoginType.FACEBOOK
        sanitizeValues.password = data?.userID
      }
      const result: any = await loginSocialMutation(sanitizeValues)
      if (result?.data?.accessToken) {
        toast.success('Login successful', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        navigate('/user-dashboard')
      } else {
        toast.error(result?.error?.data?.message || 'Something went wrong!', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (err: any) {
      toast.error(err?.data?.message, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
        theme: 'light',
      })
    }
  }

  return (
    <div id="login">
      <aside>
        <figure>
          <Link to="/">
            <img
              src="/images/methodobjects.png"
              // width="140"
              height="35"
              alt=""
              className="logo_sticky"
            />
          </Link>
        </figure>
        <div className="access_social">
          <LoginSocialFacebook
            appId={FB_APP_ID}
            fieldsProfile={
              'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={() => {}}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }: IResolveParams) => {
              if (data) {
                _handleSocialLogin(provider as LoginType.FACEBOOK, data)
              }
            }}
            onReject={(err) => {
              // eslint-disable-next-line no-console
              console.log(err)
            }}
          >
            <div className="social_bt facebook">Login with Facebook</div>
          </LoginSocialFacebook>
          <LoginSocialGoogle
            client_id={GG_APP_ID}
            onLoginStart={onLoginStart}
            redirect_uri={REDIRECT_URI}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={({ provider, data }: IResolveParams) => {
              if (data) {
                _handleSocialLogin(provider as LoginType.GOOGLE, data)
              }
            }}
            onReject={(err) => {
              // eslint-disable-next-line no-console
              console.log(err)
            }}
          >
            <div className="social_bt google">Login with Google</div>
          </LoginSocialGoogle>
        </div>
        <div className="divider">
          <span>Or</span>
        </div>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Name"
              name="first_name"
              onChange={formik.handleChange}
              value={formik.values.first_name}
            />
            <i className="ti-user" />
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="invalid-feedback-rv">
                {formik.errors.first_name}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Last Name"
              name="last_name"
              onChange={formik.handleChange}
              value={formik.values.last_name}
            />
            <i className="ti-user" />
            {formik.touched.last_name && formik.errors.last_name ? (
              <div className="invalid-feedback-rv">
                {formik.errors.last_name}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <i className="icon_mail_alt" />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback-rv">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <i className="icon_lock_alt" />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback-rv">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
            />
            <i className="icon_lock_alt" />
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <div className="invalid-feedback-rv">
                {formik.errors.confirm_password}
              </div>
            ) : null}
          </div>
          <div id="pass-info" className="clearfix" />
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn_1 full-width rounded"
          >
            Register Now!
          </button>
          <div className="add_top_10 text-center">
            Already have an account?{' '}
            <strong>
              <Link to="/login">Sign In</Link>
            </strong>
          </div>
        </form>
        <div className="copy">© 2021 Customer Advocates</div>
      </aside>
    </div>
  )
}

export default Register
