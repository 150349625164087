export const ratingHtml = (rating: number) => {
  const html = []
  for (let k = 1; k <= 5; k++) {
    /* if (rating < k) {
      html.push(<i key={k} className={`icon_star empty`} />)
    } else */ if (rating < k) {
      if (!Number.isInteger(rating) && Math.round(rating) === k) {
        html.push(<i key={k} className={`icon_star-half`} />)
      } else {
        html.push(<i key={k} className={`icon_star empty`} />)
      }
    } else {
      html.push(<i key={k} className={`icon_star`} />)
    }
    /*  html.push(
      <i
        key={k}
        className={`icon_star ${k > rating || rating === 0 ? 'empty' : ''}`}
      />
    ) */
  }
  return html
}

export const timeStampToDate = (timeStampDate: ITimeType) => {
  const dateInMillisecond = timeStampDate._seconds * 1000
  return (
    new Date(dateInMillisecond).toDateString() +
    ' at ' +
    new Date(dateInMillisecond).toLocaleTimeString()
  )
}

export const timeStampToDateShort = (timeStampDate: ITimeType) => {
  const dateInMillisecond = timeStampDate._seconds * 1000
  return new Date(dateInMillisecond).toLocaleDateString('en-GB')
}

export interface ITimeType {
  _seconds: number
  _nanoseconds: number
}

export const truncateAndDecodeHTML = (
  htmlString: string | undefined,
  length: number
) => {
  if (!htmlString) return ''
  // Decode HTML entities
  const decodedHtml =
    new DOMParser().parseFromString(htmlString, 'text/html').body.textContent ||
    ''

  // Truncate to the specified length
  const truncatedText = decodedHtml.substring(0, length)

  // Add three dots if the text was truncated
  return decodedHtml.length > length ? `${truncatedText}...` : truncatedText
}
