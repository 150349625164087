import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogLabel,
} from '@reach/alert-dialog'
import { useFormik } from 'formik'
import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { useLazyForgotPasswordQuery } from '../../services/user'

import LoginForm from './login-form'
import './style.css'

interface ILoginDialog {
  onDismiss: () => void
}
const LoginDialog: React.FC<ILoginDialog> = ({ onDismiss }) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const [showForgotPW, setShowForgotPW] = useState<boolean>(false)
  const onOpenForgotPassword = () => setShowForgotPW(true)
  const [setForgotPassword] = useLazyForgotPasswordQuery()

  const ForgotPwSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const { email } = values
        const result: any = await setForgotPassword(email)
        if (result?.error) {
          toast.error(result?.error?.data?.message || 'Something went wrong!', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.success(
            'Sent email reset password successful! Please check your email',
            {
              autoClose: 5000,
              closeOnClick: true,
              draggable: true,
              hideProgressBar: false,
              pauseOnHover: true,
              position: 'top-right',
              progress: undefined,
              theme: 'light',
            }
          )
          onDismiss?.()
        }
        setSubmitting(false)
      } catch (error: any) {
        toast.error(error?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: ForgotPwSchema,
  })

  return (
    <AlertDialog onDismiss={onDismiss} leastDestructiveRef={cancelRef}>
      <div id="sign-in-dialog" className="zoom-anim-dialog">
        <AlertDialogLabel>
          <div className="small-dialog-header">
            <h3>Sign In</h3>
          </div>
        </AlertDialogLabel>
        <AlertDialogContent>
          {showForgotPW ? (
            <form onSubmit={formik.handleSubmit}>
              <div id="forgot_pw">
                <div className="form-group">
                  <label htmlFor="email_forgot">
                    Please confirm login email below
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <i className="icon_mail_alt" />
                </div>
                <p>
                  You will receive an email containing a link allowing you to
                  reset your password to a new preferred one.
                </p>
                <div className="text-center">
                  <input
                    type="submit"
                    defaultValue="Reset Password"
                    className="btn_1"
                    disabled={formik.isSubmitting}
                  />
                </div>
              </div>
            </form>
          ) : (
            <LoginForm
              onOpenForgotPassword={onOpenForgotPassword}
              onDismiss={onDismiss}
            />
          )}
        </AlertDialogContent>
      </div>
    </AlertDialog>
  )
}

export default LoginDialog
