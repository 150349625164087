import React from 'react'
import { Link } from 'react-router-dom'

import { useGetPostsListQuery } from '../../services/post'
import { useGetPostCategoriesListQuery } from '../../services/post-category'
import { timeStampToDateShort } from '../../utils/helpers'

const BlogSidebar = () => {
  const { data: categories, isLoading: isLoadingCategories } =
    useGetPostCategoriesListQuery({
      limit: 100,
      page: 1,
    })

  const { data, isLoading } = useGetPostsListQuery({ limit: 3, page: 1 })

  const listCategories = () => {
    if (isLoadingCategories || !categories || !categories?.length) return null

    return categories?.map((category) => (
      <li key={category?._id}>
        <Link to={`/post-category/${category?.slug}`}>{category?.name}</Link>{' '}
        <span>({category?.total_posts})</span>
      </li>
    ))
  }

  const latestPost = () => {
    if (isLoading || !data || !data.length) return null
    return data?.map((post) => (
      <li key={post?._id}>
        <div className="alignleft">
          <Link to={`/post/${post?.slug}`}>
            <img src={post?.thumbnail} alt="" />
          </Link>
        </div>
        <small>
          {post?.post_category_ref?.name}
          {' - '}
          {timeStampToDateShort(post.created_at)}
        </small>
        <h3>
          <Link to={`/post/${post?.slug}`}>{post?.name}</Link>
        </h3>
      </li>
    ))
  }

  return (
    <aside className="col-lg-3">
      <div className="widget search_blog">
        <div className="form-group">
          <input
            type="text"
            name="search"
            id="search"
            className="form-control"
            placeholder="Search.."
          />
          <span>
            <input type="submit" defaultValue="Search" />
          </span>
        </div>
      </div>
      {/* /widget */}
      <div className="widget">
        <div className="widget-title">
          <h4>Latest Post</h4>
        </div>
        <ul className="comments-list">{latestPost()}</ul>
      </div>
      {/* /widget */}
      <div className="widget">
        <div className="widget-title">
          <h4>Categories</h4>
        </div>
        <ul className="cats">{listCategories()}</ul>
      </div>
    </aside>
  )
}

export default BlogSidebar
