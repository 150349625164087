import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import AboutUs from './components/about-us/about-us'
import AllCategories from './components/all-categories'
import AllProducts from './components/all-products'
import AuthConfirm from './components/auth-confirm/auth-confirm'
import Blog from './components/blog/blog'
import CategoryCompanies from './components/category-companies'
import Companies from './components/companies'
import Contacts from './components/contacts'
import ErrorView from './components/error-view'
import Faq from './components/faq'
import ForgotPassword from './components/forgot-password'
import Help from './components/help'
import Home from './components/home'
import Home2 from './components/home-2'
import Layout from './components/layout'
import ListingReviews from './components/listing-reviews'
import Login from './components/login'
import Post from './components/post'
import Privacy from './components/privacy'
// import Quote from './components/quote'
import Register from './components/register'
import RequireUser from './components/require-user'
import ResetPassword from './components/reset-password'
import ReviewsPage from './components/reviews-page'
import Survey from './components/survey'
import TermOfUse from './components/term-of-use'
import UserDashboard from './components/user-dashboard'
import UserSettings from './components/user-settings'
import WriteReview from './components/write-review'
import { RootNavigationRoute } from './constants/navigation'
/* import { Counter } from './features/counter/Counter'
import { useGetDocsListQuery } from './services/docs' */
import 'react-toastify/dist/ReactToastify.css'

const App: React.FC = () => {
  // const { data, error, isLoading } = useGetDocsListQuery()

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={RootNavigationRoute.HOME2} element={<Home2 />} />
          <Route
            path={RootNavigationRoute.REVIEWS_PAGE}
            element={<ReviewsPage />}
          />
          <Route
            element={<RequireUser /* allowedRoles={['user', 'admin']} */ />}
          >
            <Route path={RootNavigationRoute.QUOTE} element={<Survey />} />
          </Route>
          <Route
            element={<RequireUser /* allowedRoles={['user', 'admin']} */ />}
          >
            <Route
              path={RootNavigationRoute.WRITE_REVIEW}
              element={<WriteReview />}
            />
          </Route>
          <Route
            path={RootNavigationRoute.LISTING_REVIEWS}
            element={<ListingReviews />}
          />
          <Route path={RootNavigationRoute.REGISTER} element={<Register />} />
          <Route path={RootNavigationRoute.LOGIN} element={<Login />} />
          <Route
            path={RootNavigationRoute.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route
            path={RootNavigationRoute.RESET_PASSWORD}
            element={<ResetPassword />}
          />
          <Route path={RootNavigationRoute.COMPANIES} element={<Companies />} />
          <Route path={RootNavigationRoute.ABOUT_US} element={<AboutUs />} />
          <Route path={RootNavigationRoute.FAQ} element={<Faq />} />
          <Route path={RootNavigationRoute.HELP} element={<Help />} />
          <Route path={RootNavigationRoute.CONTACTS} element={<Contacts />} />
          <Route path={RootNavigationRoute.BLOG} element={<Blog />} />
          <Route path={RootNavigationRoute.POST_CATEGORY} element={<Blog />} />
          <Route path={RootNavigationRoute.BLOG_POST} element={<Post />} />
          <Route path={RootNavigationRoute.PRIVACY} element={<Privacy />} />
          <Route
            path={RootNavigationRoute.TERM_OF_USE}
            element={<TermOfUse />}
          />
          <Route
            path={RootNavigationRoute.CATEGORY_COMPANIES}
            element={<CategoryCompanies />}
          />
          <Route
            path={RootNavigationRoute.ALL_PRODUCTS}
            element={<AllProducts />}
          />
          <Route
            path={RootNavigationRoute.ALL_CATEGORIES}
            element={<AllCategories />}
          />
          <Route
            element={<RequireUser /* allowedRoles={['user', 'admin']} */ />}
          >
            <Route
              path={RootNavigationRoute.USER_DASHBOARD}
              element={<UserDashboard />}
            />
          </Route>
          <Route
            element={<RequireUser /* allowedRoles={['user', 'admin']} */ />}
          >
            <Route
              path={RootNavigationRoute.USER_SETTINGS}
              element={<UserSettings />}
            />
          </Route>
          <Route
            path={RootNavigationRoute.AUTH_CONFIRM}
            element={<AuthConfirm />}
          />
          <Route path="*" element={<ErrorView />} />
        </Route>
      </Routes>

      <ToastContainer />
    </>
  )
}

export default App
