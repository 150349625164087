import React from 'react'
import { Link } from 'react-router-dom'

import { useGetCategoriesListQuery } from '../../services/category'
import CallToAction from '../call-to-action'
import CategoryItem from '../category-item'
import CookieBar from '../cookie-bar'
import Hero from '../hero'
import LatestReviews from '../latest-reviews'

import './style.css'

const Home2: React.FC = () => {
  const { data } = useGetCategoriesListQuery({ limit: 8, page: 1 })

  const categories = () => {
    if (!data?.length) return null
    return data.map((category, index) => (
      <div key={index} className="col-lg-3 col-6">
        <CategoryItem
          link={`/category/${category.slug}`}
          image={category.icon}
          name={category.name}
          result={category?.total_companies || 0}
          review={category?.total_reviews || 0}
          style={1}
        />
      </div>
    ))
  }

  return (
    <>
      <CookieBar />
      <Hero style={2} />
      <div className="margin_60_35 container">
        <div className="main_title_3">
          <h2>Top Categories</h2>
          <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
          <Link to="/all-categories">View all</Link>
        </div>
        <div className="row justify-content-center">{categories()}</div>
      </div>
      <LatestReviews />
      <CallToAction />
    </>
  )
}

export default Home2
