import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './features/auth/authSlice'
import counterReducer from './features/counter/counterSlice'
import { categoryApi } from './services/category'
import { docsApi } from './services/docs'
import { postApi } from './services/post'
import { postCategoryApi } from './services/post-category'
import { postCommentApi } from './services/post-comment'
import { productApi } from './services/product/index'
import { quoteApi } from './services/quote'
import { reviewApi } from './services/review/index'
import { surveyApi } from './services/survey'
import { userApi } from './services/user'

const reducers = combineReducers({
  auth: authReducer,
  counter: counterReducer,
  [docsApi.reducerPath]: docsApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [reviewApi.reducerPath]: reviewApi.reducer,
  [quoteApi.reducerPath]: quoteApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [postCategoryApi.reducerPath]: postCategoryApi.reducer,
  [postCommentApi.reducerPath]: postCommentApi.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      docsApi.middleware,
      userApi.middleware,
      categoryApi.middleware,
      productApi.middleware,
      reviewApi.middleware,
      quoteApi.middleware,
      surveyApi.middleware,
      postApi.middleware,
      postCategoryApi.middleware,
      postCommentApi.middleware,
    ]),
  reducer: persistedReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
