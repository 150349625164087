import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { useResetPasswordMutation } from '../../services/user'
import type { IResetPassword } from '../../services/user/types'

import './style.css'

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [resetPasswordMutation] = useResetPasswordMutation()

  useEffect(() => {
    document.body.classList.add('body_bg')
    return () => {
      document.body.classList.remove('body_bg')
    }
  })

  const ResetPwSchema = Yup.object().shape({
    confirm_password: Yup.string()
      .label('confirm password')
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  const formik = useFormik({
    initialValues: {
      confirm_password: '',
      email: '',
      password: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const sanitizeValues = {
          email: values.email,
          password: values.password,
          token,
        } as IResetPassword
        const result: any = await resetPasswordMutation(sanitizeValues)
        if (result?.error) {
          toast.error(result?.error?.data?.message || 'Something went wrong!', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.success('Your password successfully changed!', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
          navigate('/login')
        }
        setSubmitting(false)
      } catch (error: any) {
        toast.error(error?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: ResetPwSchema,
  })

  useEffect(() => {
    if (!token) {
      toast.error('Invalid token to reset password', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
        theme: 'light',
      })
      navigate('/')
    }
  }, [token])

  return (
    <div id="login">
      <aside>
        <figure>
          <Link to="/">
            <img
              src="/images/methodobjects.png"
              // width="140"
              height="35"
              alt=""
              className="logo_sticky"
            />
          </Link>
        </figure>

        <form onSubmit={formik.handleSubmit} className="forgot-password-form">
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <i className="icon_mail_alt" />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback-rv">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              placeholder="Password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <i className="icon_lock_alt" />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback-rv">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
            />
            <i className="icon_lock_alt" />
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <div className="invalid-feedback-rv">
                {formik.errors.confirm_password}
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn_1 full-width rounded"
          >
            Save
          </button>
          <div className="add_top_10 text-center">
            Already have an account?{' '}
            <strong>
              <Link to="/login">Sign In</Link>
            </strong>
          </div>
        </form>

        <div className="copy">© 2021 Customer Advocates</div>
      </aside>
    </div>
  )
}

export default ResetPassword
