/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { logout, setCredentials } from '../../features/auth/authSlice'
import { useAuth } from '../../hooks/useAuth'
import { useWindowSize } from '../../hooks/useWindowSize'
import LoginDialog from '../login-dialog'
import Menu from '../menu'
import './style.css'

interface IHeader {
  style?: string
}

const Header: React.FC<IHeader> = ({ style }) => {
  const [showDialog, setShowDialog] = useState(false)
  const auth = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const size = useWindowSize()
  const isMobile = size?.width && size?.width < 992 ? true : false

  const onOpenDialog = () => setShowDialog(true)

  // make sure `close` and the `onClick` of the
  // `leastDestructiveRef` are identical, best to just
  // pass them both the same function
  const onCloseDialog = useCallback(() => setShowDialog(false), [])

  useEffect(() => {
    window.addEventListener('scroll', onSticky)
    return () => {
      window.removeEventListener('scroll', onSticky)
    }
  })

  const onSticky = () => {
    const header = document.querySelector('.header')
    if (header) {
      const scrollTop = window.scrollY
      scrollTop > 1
        ? header.classList.add('s_sticky')
        : header.classList.remove('s_sticky')
    }
  }

  const handleLogout = () => {
    dispatch(logout())

    dispatch(setCredentials({ accessToken: '', user: null }))
    navigate('/')
  }

  const topMenu = (
    <>
      <ul id="top_menu">
        <li>
          <Link
            to={auth?.user ? '/all-products' : '/login'}
            className="btn_top"
          >
            Write a review
          </Link>
        </li>
        <li>
          <Link to="/companies" className="btn_top company">
            For Companies
          </Link>
        </li>
        {auth?.user ? (
          <li>
            <div className="dropdown dropdown-user">
              <span className="logged" data-bs-toggle="dropdown">
                <img
                  src={auth?.user?.avatar ?? '/images/avatar4.jpeg'}
                  alt="avatar"
                />
              </span>
              <div className="dropdown-menu">
                <ul>
                  <li>
                    <Link to="/user-dashboard">My Reviews</Link>
                  </li>
                  <li>
                    <Link to="/user-settings">My Settings</Link>
                  </li>
                  <li>
                    <span onClick={handleLogout} aria-hidden="true">
                      Log Out
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        ) : (
          <li>
            <span className="login" onClick={onOpenDialog} aria-hidden="true">
              Sign In
            </span>
          </li>
        )}
      </ul>
      {showDialog && <LoginDialog onDismiss={onCloseDialog} />}
    </>
  )

  const buttonMobile = (
    <>
      <div className="btn_mobile">
        <input
          type="checkbox"
          id="mb-menu"
          name="mb-menu"
          className="m-menu__checkbox"
        />
        <label className="hamburger hamburger--spin" htmlFor="mb-menu">
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
        </label>
        <label className="m-menu__overlay" htmlFor="mb-menu" />
        <div className="m-menu">
          <div className="m-menu__header">
            <label className="m-menu__toggle" htmlFor="mb-menu">
              <svg
                width={35}
                height={35}
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth={2}
                strokeLinecap="butt"
                strokeLinejoin="bevel"
              >
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </label>
            <span>MENU</span>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/listing-reviews">Reviews</Link>
              {/* <label className="a-label__chevron" htmlFor="item-2" />
              <input
                type="checkbox"
                id="item-2"
                name="item-2"
                className="m-menu__checkbox"
              />
              <div className="m-menu">
                <div className="m-menu__header">
                  <label className="m-menu__toggle" htmlFor="item-2">
                    <svg
                      width={35}
                      height={35}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth={2}
                      strokeLinecap="butt"
                      strokeLinejoin="round"
                    >
                      <path d="M19 12H6M12 5l-7 7 7 7" />
                    </svg>
                  </label>
                  <span>Reviews</span>
                </div>
                <ul>
                  <li>
                    <Link to="/">Item 2.1</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.2</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.3</Link>
                    <label className="a-label__chevron" htmlFor="item-2-3" />
                    <input
                      type="checkbox"
                      id="item-2-3"
                      name="item-2"
                      className="m-menu__checkbox"
                    />
                    <div className="m-menu">
                      <div className="m-menu__header">
                        <label className="m-menu__toggle" htmlFor="item-2-3">
                          <svg
                            width={35}
                            height={35}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#ffffff"
                            strokeWidth={2}
                            strokeLinecap="butt"
                            strokeLinejoin="round"
                          >
                            <path d="M19 12H6M12 5l-7 7 7 7" />
                          </svg>
                        </label>
                        <span>Item 2.3 </span>
                      </div>
                      <ul>
                        <li>
                          <Link to="/">Item 2.3.1</Link>
                        </li>
                        <li>
                          <Link to="/">Item 2.3.2</Link>
                        </li>
                        <li>
                          <Link to="/">Item 2.3.3</Link>
                        </li>
                        <li>
                          <Link to="/">Item 2.3.4</Link>
                        </li>
                        <li>
                          <Link to="/">Item 2.3.5</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link to="/">Item 2.4</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.5</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.6</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.7</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.8</Link>
                  </li>
                  <li>
                    <Link to="/">Item 2.9</Link>
                  </li>
                </ul>
              </div> */}
            </li>
            <li>
              <Link to="/all-categories">Categories</Link>
            </li>
            <li>
              <Link to="/all-products">Products</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
  if (style === 'sticky') {
    return (
      <header className="header menu_fixed">
        <div id="logo">
          <Link to="/">
            {/* <Logo className="logo_normal" />
            <LogoSticky className="logo_sticky" /> */}
            <img
              src="/images/methodobjects.png"
              // width="140"
              height="35"
              alt=""
              className="logo_normal"
            />
            <img
              src="/images/methodobjects.png"
              // width="140"
              height="35"
              alt=""
              className="logo_sticky"
            />
          </Link>
        </div>
        {topMenu}
        {isMobile ? buttonMobile : <Menu />}
      </header>
    )
  } else {
    return (
      <header
        className={`header_in ${
          style === 'fixed' ? 'is_fixed menu_fixed' : ''
        } `}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12">
              <div id="logo">
                <Link to="/">
                  {/* <LogoSticky className="logo_sticky" /> */}
                  <img
                    src="/images/methodobjects.png"
                    // width="140"
                    height="35"
                    alt=""
                    className="logo_sticky"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-12">
              {topMenu}
              {isMobile ? buttonMobile : <Menu />}
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
