import React, { useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import { useGetCategoriesListQuery } from '../../services/category'
import {
  useGetReviewsListQuery,
  useSearchReviewsMutation,
} from '../../services/review'
import type { Review } from '../../services/review/types'
import { timeStampToDate } from '../../utils/helpers'
import ReviewItem from '../review-item'
import './style.css'

const ListingReviews: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamValue = searchParams.get('search')
  const categoryParamValue = searchParams.get('category')
  const searchField = useRef<HTMLInputElement>(null)

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useGetCategoriesListQuery({
      limit: 100,
      page: 1,
    })
  const [
    searchReviewsMutation,
    { data: searchData, isLoading: searchLoading },
  ] = useSearchReviewsMutation()
  const { data: reviewsData, isLoading } = useGetReviewsListQuery(
    { limit: 100, page: 1 },
    { skip: searchParamValue || categoryParamValue ? true : false }
  )

  /* const filterTop = (
    <>
      <div className="filters_listing sticky_horizontal">
        <div className="container">
          <ul className="clearfix">
            <li>
              <div className="switch-field">
                <input
                  type="radio"
                  id="all"
                  name="listing_filter"
                  defaultValue="all"
                  data-filter="*"
                  className="selected"
                />
                <label htmlFor="all">All</label>
                <input
                  type="radio"
                  id="latest"
                  name="listing_filter"
                  defaultValue="latest"
                  data-filter=".latest"
                />
                <label htmlFor="latest">Latest</label>
                <input
                  type="radio"
                  id="oldest"
                  name="listing_filter"
                  defaultValue="oldest"
                  data-filter=".oldest"
                />
                <label htmlFor="oldest">Oldest</label>
              </div>
            </li>
            <li>
              <a
                className="btn_filt"
                data-bs-toggle="collapse"
                href="#filters"
                aria-expanded="false"
                aria-controls="filters"
                data-text-swap="Less filters"
                data-text-original="More filters"
              >
                More filters
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="collapse" id="filters">
        <div className="margin_30_5 container">
          <div className="row">
            <div className="col-md-4">
              <h6>Rating</h6>
              <ul>
                <li>
                  <label className="container_check">
                    Superb 9+ <small>67</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="container_check">
                    Very Good 8+ <small>89</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="container_check">
                    Good 7+ <small>45</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="container_check">
                    Pleasant 6+ <small>78</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h6>Categories</h6>
              <ul>
                <li>
                  <label className="container_check">
                    Restaurants <small>12</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="container_check">
                    Clothes <small>11</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="container_check">
                    Bars <small>23</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
                <li>
                  <label className="container_check">
                    Events <small>56</small>
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="add_bottom_30">
                <h6>Distance</h6>
                <div className="distance">
                  {' '}
                  Radius around selected destination <span /> km
                </div>
                <input
                  type="range"
                  min={10}
                  max={100}
                  step={10}
                  defaultValue={30}
                  data-orientation="horizontal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) */

  useEffect(() => {
    window.addEventListener('scroll', onSticky)
    return () => {
      window.removeEventListener('scroll', onSticky)
    }
  })

  const onSticky = () => {
    const results = document.getElementById('results')
    const filters_listing = document.querySelector('.filters_listing')

    const scrollTop = window.scrollY
    if (results) {
      if (scrollTop > 58) {
        results.classList.add('is_stuck')
        if (filters_listing) {
          filters_listing.classList.add('is_stuck')
        }
      } else {
        results.classList.remove('is_stuck')
        if (filters_listing) {
          filters_listing.classList.remove('is_stuck')
        }
      }
    }
  }

  let options = [{ label: 'All Categories', value: '' }]
  if (categoriesData?.length) {
    const categories = JSON.stringify(
      categoriesData.map(({ _id, name }) => ({ label: name, value: _id }))
    )

    options = [...new Set([...options, ...JSON.parse(categories)])]
  }

  useEffect(() => {
    if (categoryParamValue || searchParamValue)
      searchReviewsMutation({
        categoryId: categoryParamValue || '',
        company: searchParamValue || '',
      }).unwrap()
  }, [searchParamValue, categoryParamValue])

  const currentCategory = useMemo(() => {
    if (categoriesData?.length) {
      const categoryIndex = categoriesData.find(
        ({ _id }) => _id === categoryParamValue
      )
      return categoryIndex ? categoryIndex.name : 'All Categories'
    }
    return 'All Categories'
  }, [categoriesData, categoryParamValue])

  const handleSubmitSearch = (event: any) => {
    event.preventDefault()
    const category = event.target.category.value
    const search = searchField.current?.value
    setSearchParams({
      ...(category && { category }),
      ...(search && { search }),
    })
  }

  const renderContent = () => {
    if ((searchLoading && !searchData) || (isLoading && !reviewsData))
      return Array.from({ length: 6 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="col-xl-4 col-lg-6 col-md-6 isotope-item latest is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="review_listing">
                <div className="image-loading"></div>
                <h3>&nbsp;</h3>
                <h4>&nbsp;</h4>
              </div>
            </div>
          )
        })

    if (searchData?.length) {
      return searchData.map((review: Review) => (
        <div
          className="col-xl-4 col-lg-6 col-md-6 isotope-item latest"
          key={review._id}
        >
          <ReviewItem
            image={review?.owner_ref?.avatar || '/images/avatar4.jpeg'}
            author={`${review?.owner_ref?.first_name} ${review?.owner_ref?.last_name}`}
            description={review.content || ''}
            published={timeStampToDate(review.created_at)}
            title={review.title}
            name={review.category_ref?.name || ''}
            rating={review.rating}
            product={review?.company_ref?.name}
            link={`/product/${review?.company_ref?.slug}`}
            style={3}
          />
        </div>
      ))
    }

    if (reviewsData?.length) {
      return reviewsData.map((review: Review) => (
        <div
          className="col-xl-4 col-lg-6 col-md-6 isotope-item latest"
          key={review._id}
        >
          <ReviewItem
            image={review?.owner_ref?.avatar || '/images/avatar4.jpeg'}
            author={`${review?.owner_ref?.first_name} ${review?.owner_ref?.last_name}`}
            description={review.content || ''}
            published={timeStampToDate(review.created_at)}
            title={review.title}
            name={review.category_ref?.name || ''}
            rating={review.rating}
            product={review?.company_ref?.name}
            link={`/product/${review?.company_ref?.slug}`}
            style={3}
          />
        </div>
      ))
    }
    return (
      <div className="full-width-auto">
        <div className="text-center">Review is empty!</div>
      </div>
    )
  }

  return (
    <>
      <div id="results">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-4 col-10">
              <h1>
                <strong>
                  {searchData && searchData.length
                    ? searchData.length
                    : reviewsData && reviewsData.length
                    ? reviewsData.length
                    : 0}
                </strong>{' '}
                result for "{currentCategory}"
              </h1>
            </div>
            <div className="col-xl-5 col-md-6 col-2">
              <span className="search_mob btn_search_mobile" />{' '}
              <form onSubmit={handleSubmitSearch}>
                <div className="row g-0 custom-search-input-2 inner">
                  <div className="col-lg-7">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search reviews for a product"
                        ref={searchField}
                      />
                      <i className="icon_search" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <Select
                      className="category-select"
                      classNamePrefix="rv-custom-select"
                      options={options}
                      defaultValue={options[0]}
                      isSearchable={false}
                      isLoading={isLoadingCategories}
                      name="category"
                    />
                  </div>
                  <div className="col-xl-1 col-lg-1">
                    <input type="submit" defaultValue="Search" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="search_mob_wp">
            <div className="custom-search-input-2">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search reviews..."
                />
                <i className="icon_search" />
              </div>
              <Select
                className="category-select"
                classNamePrefix="rv-custom-select"
                options={options}
                defaultValue={options[0]}
                isSearchable={false}
                isLoading={isLoadingCategories}
                name="category"
              />
              <input type="submit" defaultValue="Search" />
            </div>
          </div>
        </div>
      </div>
      {/* {filterTop} */}
      <div className="margin_60_35 container">
        <div className="isotope-wrapper">
          <div className="row">{renderContent()}</div>
        </div>
        {/* <p className="text-center">
          <a href="#0" className="btn_1 add_top_15 rounded">
            Load more
          </a>
        </p> */}
      </div>
    </>
  )
}

export default ListingReviews
