import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogLabel,
} from '@reach/alert-dialog'
import { useFormik } from 'formik'
import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { useAuth } from '../../hooks/useAuth'
import {
  useDeleteReviewMutation,
  useGetReviewsByOwnerQuery,
  useUpdateReviewMutation,
} from '../../services/review'
import type { Review } from '../../services/review/types'
import { timeStampToDate } from '../../utils/helpers'
import Checkbox from '../checkbox'
import ReviewCard from '../review-card'
import UserSummary from '../user-summary'

import './style.css'

const UserDashboard: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [currentReviewEdit, setCurrentReviewEdit] = useState<Review | null>(
    null
  )
  const cancelRef = useRef<HTMLButtonElement>(null)

  if (!auth || !auth.user) {
    navigate('/login')
  }
  const { user } = auth
  const { data: reviewsData, isLoading, refetch } = useGetReviewsByOwnerQuery()

  const [deleteReviewMutation] = useDeleteReviewMutation()
  const [updateReviewMutation] = useUpdateReviewMutation()

  const onDeleteReview = async (review: Review) => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure to delete review?') == true) {
      try {
        if (review?._id) {
          await deleteReviewMutation(review?._id).unwrap()
          toast.success('Delete review successful', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
          refetch()
        }
      } catch (err: any) {
        toast.error(err?.data?.description, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
      }
    }
  }

  const onDismiss = () => {
    setCurrentReviewEdit(null)
  }

  const WriteReviewSchema = Yup.object().shape({
    content: Yup.string().required('Review content is required'),
    rating: Yup.number()
      .integer()
      .min(1)
      .max(5)
      .required('Rating should be greater than 0'),
    term_agree: Yup.bool().oneOf(
      [true],
      'The terms and conditions must be accepted.'
    ),
    title: Yup.string().required('Review title is required'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: currentReviewEdit?.content || '',
      rating: currentReviewEdit?.rating || 1,
      term_agree: false,
      title: currentReviewEdit?.title || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const sanitizeValues = {
          company_id: currentReviewEdit?.company_id,
          content: values.content,
          id: currentReviewEdit?._id as string,
          rating: Number(values.rating),
          title: values.title,
        }
        await updateReviewMutation(sanitizeValues).unwrap()
        toast.success('Delete review successful', {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
        refetch()
        onDismiss()
      } catch (err: any) {
        toast.error(err?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: WriteReviewSchema,
  })

  const onEditReview = (review: Review) => {
    setCurrentReviewEdit(review)
    formik.setFieldValue('rating', review.rating)
  }

  const reviews = () => {
    if (isLoading)
      return Array.from({ length: 6 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="review_card is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="row">
                <div className="col-md-2 user_info">
                  <figure className="image-loading"></figure>
                  <h5>&nbsp;</h5>
                </div>
                <div className="col-md-10 review_content">
                  <h4>&nbsp;</h4>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          )
        })
    if (!reviewsData || !reviewsData.length) return <>Not found review!</>
    return reviewsData.map((review, index) => (
      <ReviewCard
        image={user?.avatar || '/images/avatar4.jpeg'}
        title={review.title}
        published={timeStampToDate(review.created_at)}
        name={review.company_ref?.name || ''}
        description={review.content || ''}
        link={`/product/${review?.company_ref?.slug}`}
        rating={review.rating}
        key={index}
        style={2}
        onDelete={() => onDeleteReview(review)}
        onEdit={() => onEditReview(review)}
      />
    ))
  }

  const reviewDialog = () => {
    return (
      <AlertDialog
        onDismiss={onDismiss}
        leastDestructiveRef={cancelRef}
        className="edit-review-dialog-container"
      >
        <div id="edit-review-dialog" className="zoom-anim-dialog">
          <AlertDialogLabel>
            <div className="small-dialog-header">
              <h3>Edit review</h3>
            </div>
          </AlertDialogLabel>
          <AlertDialogContent>
            <form
              className="box_general update_review"
              onSubmit={formik.handleSubmit}
            >
              <div className="rating_submit">
                <div className="form-group">
                  <p className="d-block">Overall rating</p>
                  <span className="rating">
                    <input
                      type="radio"
                      className="rating-input"
                      id="5_star"
                      name="rating"
                      value={5}
                      defaultChecked={formik.values.rating === 5}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="5_star" className="rating-star">
                      &nbsp;
                    </label>
                    <input
                      type="radio"
                      className="rating-input"
                      id="4_star"
                      name="rating"
                      value={4}
                      defaultChecked={formik.values.rating === 4}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="4_star" className="rating-star">
                      &nbsp;
                    </label>
                    <input
                      type="radio"
                      className="rating-input"
                      id="3_star"
                      name="rating"
                      value={3}
                      defaultChecked={formik.values.rating === 3}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="3_star" className="rating-star">
                      &nbsp;
                    </label>
                    <input
                      type="radio"
                      className="rating-input"
                      id="2_star"
                      name="rating"
                      value={2}
                      defaultChecked={formik.values.rating === 2}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="2_star" className="rating-star">
                      &nbsp;
                    </label>
                    <input
                      type="radio"
                      className="rating-input"
                      id="1_star"
                      name="rating"
                      value={1}
                      defaultChecked={formik.values.rating === 1}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="1_star" className="rating-star">
                      &nbsp;
                    </label>
                  </span>
                </div>
                {formik.touched.rating && formik.errors.rating ? (
                  <div className="invalid-feedback-rv">
                    {formik.errors.rating}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="title">Title of your review</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="If you could say it in one sentence, what would you say?"
                  id="title"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="invalid-feedback-rv">
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="content">Your review</label>
                <textarea
                  className="form-control"
                  style={{ height: 180 }}
                  placeholder="Write your review to help others learn about this online business"
                  name="content"
                  id="content"
                  onChange={formik.handleChange}
                  value={formik.values.content}
                />
                {formik.touched.content && formik.errors.content ? (
                  <div className="invalid-feedback-rv">
                    {formik.errors.content}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <div className="checkboxes float-start add_bottom_15 add_top_15">
                  <Checkbox
                    label={`Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod
                  scaevola sea. Et nec tantas accusamus salutatus, sit commodo
                  veritus te, erat legere fabulas has ut. Rebum laudem cum ea,
                  ius essent fuisset ut. Viderer petentium cu his.`}
                    name="term_agree"
                    onChange={formik.handleChange}
                  />
                  {formik.touched.term_agree && formik.errors.term_agree ? (
                    <div className="invalid-feedback-rv">
                      {formik.errors.term_agree}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="btn_1"
              >
                Update
              </button>
            </form>
          </AlertDialogContent>
        </div>
      </AlertDialog>
    )
  }

  return (
    <>
      <UserSummary />
      <div className="margin_60_35 container">
        <div className="row">
          <div className="col-lg-8">{reviews()}</div>
          <div className="col-lg-4">
            <div className="box_general general_info">
              <h3>
                Delete a review
                <i className="pe-7s-help1" />
              </h3>
              <p>
                <strong>Mucius doctus constituto pri at.</strong> At vix utinam
                corpora, ea oblique moderatius usu. Vix id viris consul
                honestatis, an constituto deterruisset consectetuer pro quo
                corrumpit euripidis...
                <br />
                <strong>
                  <Link to="/faq">Rear more</Link>
                </strong>
              </p>
              <hr />
              <h3>
                Post a review
                <i className="pe-7s-help1" />
              </h3>
              <p>
                Dolor detraxit duo in, ei sea dicit reformidans. Mel te accumsan
                patrioque referrentur. Has causae perfecto ut, ex choro
                assueverit eum...
                <br />
                <strong>
                  <Link to="/faq">Rear more</Link>
                </strong>
              </p>
              <hr />
              <h3>
                Approve a review
                <i className="pe-7s-help1" />
              </h3>
              <p>
                Sed ne prompta insolens mediocrem, omnium fierent sed an, quod
                vivendo mel in. Argumentum honestatis ad mel, cu vis quot
                utroque...
                <br />
                <strong>
                  <Link to="/faq">Rear more</Link>
                </strong>
              </p>
              <hr />
              <div className="text-center">
                <Link to="/faq" className="btn_1 small">
                  Rear more
                </Link>
              </div>
            </div>
          </div>
        </div>
        {currentReviewEdit ? reviewDialog() : null}
      </div>
    </>
  )
}

export default UserDashboard
