import React from 'react'
import { Link } from 'react-router-dom'

import { useGetCategoriesListQuery } from '../../services/category'
import CategoryItem from '../category-item'

import './style.css'

const TopCategories: React.FC = () => {
  const { data, isLoading } = useGetCategoriesListQuery({ limit: 6, page: 1 })

  const categories = () => {
    if (isLoading)
      return Array.from({ length: 6 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="col-lg-4 col-sm-6 is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="grid_item" />
            </div>
          )
        })
    if (!data || !data.length) return null
    return data.map((category) => (
      <div key={category?._id} className="col-lg-4 col-sm-6">
        <CategoryItem
          link={`/category/${category.slug}`}
          image={category.cover_image}
          name={category.name}
          result={category?.total_companies || 0}
          review={category?.total_reviews || 0}
        />
      </div>
    ))
  }

  return (
    <div className="margin_60_35 container">
      <div className="main_title_3">
        <h2>Top Categories</h2>
        <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
        <Link to="/all-categories">View all</Link>
      </div>
      <div className="row justify-content-center">{categories()}</div>
    </div>
  )
}

export default TopCategories
