import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import { useGetReviewsListQuery } from '../../services/review'
import type { Review } from '../../services/review/types'
import { timeStampToDate } from '../../utils/helpers'
import ReviewItem from '../review-item'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.css'

const LatestReviews: React.FC = () => {
  const settings = {
    centerMode: true,
    centerPadding: '0px',
    className: 'center',
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToShow: 3,
    speed: 500,
  }
  const { data, isLoading } = useGetReviewsListQuery({ limit: 5, page: 1 })

  if (data && data?.length > 2) {
    settings.infinite = true
  }

  const reviews = () => {
    if (isLoading) {
      return Array.from({ length: 3 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="item is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="review_listing">
                <div className="image-loading"></div>
                <h3>&nbsp;</h3>
                <h4>&nbsp;</h4>
              </div>
            </div>
          )
        })
    }

    if (!data || !data?.length) return null
    return data.map((review: Review) => (
      <ReviewItem
        key={review?._id}
        image={review?.owner_ref?.avatar || '/images/avatar4.jpeg'}
        author={`${review?.owner_ref?.first_name} ${review?.owner_ref?.last_name}`}
        description={review.content || ''}
        published={timeStampToDate(review.created_at)}
        title={review.title}
        name={review.category_ref?.name || ''}
        rating={review.rating}
        product={review?.company_ref?.name}
        link={`/product/${review?.company_ref?.slug}`}
      />
    ))
  }

  return (
    <div className="bg_color_1">
      <div className="margin_60 latest-reviews container">
        <div className="main_title_3">
          <h2>Latest Reviews</h2>
          <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
          <Link to="/listing-reviews">View all</Link>
        </div>
        <Slider {...settings}>{reviews()}</Slider>
      </div>
    </div>
  )
}

export default LatestReviews
