import React from 'react'
import { Link } from 'react-router-dom'

import { ratingHtml } from '../../utils/helpers'

import type { IProductItem } from './type'

import './style.css'

const ProductItem: React.FC<IProductItem> = (props) => {
  const review_sum = props?.review_summary
  const totalReview =
    review_sum && review_sum?.total > 1
      ? `${review_sum?.total} reviews`
      : `${review_sum?.total} review`
  return (
    <div className="company_listing isotope-item">
      <div className="row">
        <div className="col-md-9">
          <div className="company_info">
            <figure>
              <Link to={`/product/${props?.slug}`}>
                <img
                  src={props?.avatar ?? '/images/brands/1_c.jpeg'}
                  alt={props?.name}
                />
              </Link>
            </figure>
            <h3>
              <Link to={`/product/${props?.slug}`}>{props?.name}</Link>
            </h3>
            {props?.bio && <p>{props?.bio}</p>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="float-lg-end text-center">
            <span className="rating">
              <strong>Based on {totalReview}</strong>
              {ratingHtml(review_sum?.average || 0)}
            </span>
            <Link to={`/write-review/${props?.slug}`} className="btn_1 small">
              Write a review
            </Link>
            {/* <Link
              to={`/quote/${props?.slug}`}
              className="btn_1 small request_quote"
            >
              Request a quote
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductItem
