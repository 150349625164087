import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useVerifyProfileQuery } from '../../services/user'

const AuthConfirm: React.FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const navigate = useNavigate()

  const { data, error } = useVerifyProfileQuery(token as string, {
    skip: !token,
  })

  useEffect(() => {
    if (data) {
      toast.success('Verify account successful', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
        theme: 'light',
      })
    }
    if (error) {
      const err: any = error
      toast.error(err?.data?.message, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
        theme: 'light',
      })
    }
  }, [data, error])

  if (error) {
    navigate('/')
  }
  if (data) {
    navigate('/login')
  }

  if (!token) {
    navigate('/')
  }
  return <></>
}

export default AuthConfirm
