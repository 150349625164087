import React from 'react'
import { Link } from 'react-router-dom'

import { ratingHtml } from '../../utils/helpers'

import type { IReviewSummary } from './type'

import './style.css'

const ReviewsSummary: React.FC<IReviewSummary> = (props) => {
  const review_sum = props?.review_summary
  const review_sum_total = review_sum?.total || 0
  const ratingNumber = review_sum?.average.toLocaleString('en', {
    minimumFractionDigits: 2,
    useGrouping: false,
  })
  const totalReview =
    review_sum_total > 1
      ? `${review_sum_total} reviews`
      : `${review_sum_total} review`

  const percentFiveStar = review_sum_total
    ? ((review_sum?.five_star || 0) / review_sum_total) * 100
    : 0
  const percentFourStar = review_sum_total
    ? ((review_sum?.four_star || 0) / review_sum_total) * 100
    : 0
  const percentThreeStar = review_sum_total
    ? ((review_sum?.three_star || 0) / review_sum_total) * 100
    : 0
  const percentTwoStar = review_sum_total
    ? ((review_sum?.two_star || 0) / review_sum_total) * 100
    : 0
  const percentOneStar = review_sum_total
    ? ((review_sum?.one_star || 0) / review_sum_total) * 100
    : 0

  return (
    <div className="reviews_summary">
      <img
        src={`${props?.cover_image ? props.cover_image : '/images/b1.jpeg'}`}
        alt={props.name}
        className="summary_cover_image"
      />
      <div className="wrapper">
        <div className="container">
          <div className="row items-end">
            <div className="col-lg-8">
              <figure>
                <img
                  src={props?.avatar ?? 'img/logo-company.png'}
                  alt={props?.name}
                />
              </figure>
              <small>{props?.category}</small>
              <h1>{props?.name}</h1>
              <ul className="summary-information">
                {props?.address ? (
                  <li>
                    <span>
                      <i className="icon-location" />
                    </span>
                    <span>{props?.address}</span>
                  </li>
                ) : null}
                {props?.website ? (
                  <li>
                    <span>
                      <i className="icon-globe" />
                    </span>
                    <span>
                      <a href={props?.website} target="_blank">
                        View website
                      </a>
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
            <div className="col-lg-4 review_detail">
              <div className="row">
                <div className="col-lg-9 col-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentFiveStar}%` }}
                      aria-valuenow={percentFiveStar}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-3 text-end">
                  <strong>5 stars</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 col-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentFourStar}%` }}
                      aria-valuenow={percentFourStar}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-3 text-end">
                  <strong>4 stars</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 col-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentThreeStar}%` }}
                      aria-valuenow={percentThreeStar}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-3 text-end">
                  <strong>3 stars</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 col-9">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentTwoStar}%` }}
                      aria-valuenow={percentTwoStar}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-3 text-end">
                  <strong>2 stars</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 col-9">
                  <div className="progress last">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentOneStar}%` }}
                      aria-valuenow={percentOneStar}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-3 text-end">
                  <strong>1 stars</strong>
                </div>
              </div>
              <div className="rating">
                {ratingHtml(review_sum?.average || 0)}
                <em>
                  {ratingNumber}/5.00 - based on {totalReview}
                </em>
              </div>
              <div className="review-actions">
                <Link
                  to={`/write-review/${props.slug}`}
                  className="btn_1 small"
                >
                  Write a review
                </Link>
                <Link
                  to={`/quote/${props.slug}`}
                  className="btn_1 small request_quote"
                >
                  Request for Quotation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewsSummary
