export enum RootNavigationRoute {
  HOME = '/',
  HOME2 = '/home-2',
  REVIEWS_PAGE = '/product/:slug',
  WRITE_REVIEW = '/write-review/:slug',
  LISTING_REVIEWS = '/listing-reviews',
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/auth/reset-password',
  COMPANIES = '/companies',
  ABOUT_US = '/about-us',
  FAQ = '/faq',
  HELP = '/help',
  CONTACTS = '/contacts',
  BLOG = '/blog',
  POST_CATEGORY = '/post-category/:slug',
  BLOG_POST = '/post/:slug',
  ALL_CATEGORIES = '/all-categories',
  USER_DASHBOARD = '/user-dashboard',
  USER_SETTINGS = '/user-settings',
  CATEGORY_COMPANIES = '/category/:slug',
  QUOTE = '/quote/:slug',
  ALL_PRODUCTS = '/all-products',
  AUTH_CONFIRM = '/auth/confirm',
  PRIVACY = '/privacy',
  TERM_OF_USE = '/termsofuse',
}
