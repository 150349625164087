import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { useGetCategoriesListQuery } from '../../services/category'
import { useGetProductsListQuery } from '../../services/product'
import CallToAction from '../call-to-action'
import CategoryItem from '../category-item'

import './style.css'

const AllCategories: React.FC = () => {
  const navigate = useNavigate()

  const { data, isLoading } = useGetCategoriesListQuery({ limit: 100, page: 1 })
  const { data: productsData, isLoading: isLoadingProducts } =
    useGetProductsListQuery({ limit: 100, page: 1 })

  let options = [{ label: 'All Categories', value: '' }]
  if (data?.length) {
    const categories = JSON.stringify(
      data.map(({ _id, name }) => ({ label: name, value: _id }))
    )

    options = [...new Set([...options, ...JSON.parse(categories)])]
  }
  const searchField = useRef<HTMLInputElement>(null)

  const categories = () => {
    if (isLoading) {
      return Array.from({ length: 8 })
        .fill(null)
        .map((value, index) => {
          return (
            <div
              className="col-lg-3 col-6 is-loading-skeleton"
              key={`thumbnail-${index}`}
            >
              <div className="box_cat_home">
                <div className="image-loading"></div>
                <h3>&nbsp;</h3>
                <h4>&nbsp;</h4>
              </div>
            </div>
          )
        })
    }
    if (!data || !data.length) return null
    return data.map((category) => (
      <div key={category?._id} className="col-lg-3 col-6">
        <CategoryItem
          link={`/category/${category.slug}`}
          image={category.icon}
          name={category.name}
          result={category?.total_companies || 0}
          review={category?.total_reviews || 0}
          style={1}
        />
      </div>
    ))
  }

  const companiesCategories = () => {
    if (isLoadingProducts) return <>Loading data</>
    if (!productsData || !productsData.length) return null

    return productsData.map((productCategory) => (
      <li key={productCategory._id}>
        <Link to={`/product/${productCategory?.slug}`}>
          <strong>{productCategory.review_summary?.total || 0}</strong>
          {productCategory.name}
        </Link>
      </li>
    ))
  }

  const handleSubmitSearch = (event: any) => {
    event.preventDefault()
    const category = event.target.category.value
    const search = searchField.current?.value
    navigate(`/listing-reviews?search=${search}&category=${category}`)
  }

  return (
    <>
      <section className="hero_single version_3">
        <div className="wrapper">
          <div className="container">
            <h3>Search Reviews by Categories</h3>
            <p>Check Ratings of Businesses, Read Reviews &amp; Buy</p>
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <form onSubmit={handleSubmitSearch}>
                  <div className="row g-0 custom-search-input-2">
                    <div className="col-lg-7">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search for a product..."
                          name="search"
                          ref={searchField}
                        />
                        <i className="icon_search" />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <Select
                        className="category-select"
                        classNamePrefix="rv-custom-select"
                        options={options}
                        defaultValue={options[0]}
                        isSearchable={false}
                        isLoading={isLoading}
                        name="category"
                      />
                    </div>
                    <div className="col-lg-2">
                      <input type="submit" defaultValue="Search" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="margin_60_35 container">
        <div className="main_title_2">
          <h1>Top Categories</h1>
          <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
        </div>
        <div className="row justify-content-center">{categories()}</div>
      </div>
      <div className="bg_color_1">
        <div className="margin_60_35 container">
          <div className="main_title_3 text-center">
            <h2>Customer Advocates companies categories</h2>
            <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12">
              <div className="all_categories clearfix add_bottom_30">
                <ul>{companiesCategories()}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction style={2} />
    </>
  )
}

export default AllCategories
