import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import './style.css'
import { useStatisticQuery } from '../../services/user'

const UserSummary: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  if (!auth || !auth.user) {
    navigate('/login')
  }
  const { user } = auth

  const { data: statisticData } = useStatisticQuery()

  const fullName = [user?.first_name, user?.last_name]
    .filter((item) => item)
    .join(' ')
  const address = [user?.city, user?.country].filter((item) => item).join(', ')

  return (
    <div className="user_summary">
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <figure>
                <img
                  src={user?.avatar ?? '/images/avatar4.jpeg'}
                  alt={fullName}
                />
              </figure>
              {fullName && <h1>{fullName}</h1>}
              {address && <span>{address}</span>}
            </div>
            <div className="col-md-6">
              <ul>
                <li>
                  <strong>{statisticData?.total_reviews ?? 0}</strong>
                  <a
                    href="#0"
                    className="tooltips"
                    data-bs-toggle="tooltip"
                    data-placement="bottom"
                    title="Reviews written by you"
                  >
                    <i className="icon_star" /> Reviews
                  </a>
                </li>
                {/* <li>
                  <strong>{statisticData?.total_useful ?? 0}</strong>
                  <a
                    href="#0"
                    className="tooltips"
                    data-bs-toggle="tooltip"
                    data-placement="bottom"
                    title="Number of people who have read your reviews"
                  >
                    <i className="icon-user-1" /> Reads
                  </a>
                </li> */}
                <li>
                  <strong>{statisticData?.total_useful ?? 0}</strong>
                  <a
                    href="#0"
                    className="tooltips"
                    data-bs-toggle="tooltip"
                    data-placement="bottom"
                    title="Number of people who found your review useful"
                  >
                    <i className="icon_like_alt" /> Useful
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSummary
