import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import './index.css'

import App from './App'
import { store } from './store'
import ScrollToTop from './utils/scrollToTop'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
)
const persistor = persistStore(store)

if (process.env.NODE_ENV === 'development') {
  import('../mocks/browser')
    .then(({ worker }) => {
      worker.start()
    })
    .then(() => {
      root.render(
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      )
    })
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  )
}
