import React from 'react'

import type { IProduct } from './type'
import './style.css'

const ProductInformation: React.FC<IProduct> = ({
  name,
  description,
  address,
  company,
  website,
  country,
  telephone,
  net_promoter_score,
}) => {
  return (
    <div className="box_general company_info">
      <h3>{name}</h3>
      {description && <p>{description}</p>}
      {company && (
        <p>
          <strong>Company</strong>
          <br />
          {company}
        </p>
      )}
      {address && (
        <p>
          <strong>Location:</strong>
          <br />
          {address}
        </p>
      )}
      {country && (
        <p>
          <strong>Country:</strong>
          <br />
          {country}
        </p>
      )}
      {net_promoter_score && (
        <p>
          <strong>Rating:</strong>
          <br />
          {net_promoter_score}
        </p>
      )}
      {website && (
        <p>
          <strong>Learning Channel</strong>
          <br />
          <a href={website}>{website}</a>
        </p>
      )}
      {telephone && (
        <p>
          <strong>Customer Service</strong>
          <br />
          {telephone}
        </p>
      )}
      <p className="follow_company">
        <strong>Follow us</strong>
        <br />
        <a href="#0">
          <i className="social_facebook_circle" />
        </a>
        <a href="#0">
          <i className="social_twitter_circle" />
        </a>
        <a href="#0">
          <i className="social_googleplus_circle" />
        </a>
        <a href="#0">
          <i className="social_instagram_circle" />
        </a>
      </p>
    </div>
  )
}

export default ProductInformation
