import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { useLazyForgotPasswordQuery } from '../../services/user'

import './style.css'

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate()
  const [setForgotPassword] = useLazyForgotPasswordQuery()

  useEffect(() => {
    document.body.classList.add('body_bg')
    return () => {
      document.body.classList.remove('body_bg')
    }
  })

  const ForgotPwSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const { email } = values
        const result: any = await setForgotPassword(email)
        if (result?.error) {
          toast.error(result?.error?.data?.message || 'Something went wrong!', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.success(
            'Sent email reset password successful! Please check your email',
            {
              autoClose: 5000,
              closeOnClick: true,
              draggable: true,
              hideProgressBar: false,
              pauseOnHover: true,
              position: 'top-right',
              progress: undefined,
              theme: 'light',
            }
          )
          navigate('/login')
        }
        setSubmitting(false)
      } catch (error: any) {
        toast.error(error?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: ForgotPwSchema,
  })

  return (
    <div id="login">
      <aside>
        <figure>
          <Link to="/">
            <img
              src="/images/methodobjects.png"
              // width="140"
              height="35"
              alt=""
              className="logo_sticky"
            />
          </Link>
        </figure>

        <form onSubmit={formik.handleSubmit} className="forgot-password-form">
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <i className="icon_mail_alt" />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback-rv">{formik.errors.email}</div>
            ) : null}
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn_1 full-width rounded"
          >
            Reset Password
          </button>
          <div className="add_top_10 text-center">
            Already have an account?{' '}
            <strong>
              <Link to="/login">Sign In</Link>
            </strong>
          </div>
        </form>

        <div className="copy">© 2021 Customer Advocates</div>
      </aside>
    </div>
  )
}

export default ForgotPassword
