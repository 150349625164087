import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { useAuth } from '../../hooks/useAuth'
import { useGetPostsBySlugQuery } from '../../services/post'
import {
  useAddCommentMutation,
  useLazyGetPostCommentsListQuery,
} from '../../services/post-comment'
import type { PostComment } from '../../services/post-comment/types'
import { timeStampToDateShort } from '../../utils/helpers'
import BlogSidebar from '../blog-sidebar/blog-sidebar'

import './style.css'

function buildCommentTree(comments: PostComment[]): PostComment[] {
  const commentMap: { [key: string]: PostComment } = {}
  const commentTree: PostComment[] = []

  // Create a map of comments using their _id as the key
  comments.forEach((comment) => {
    commentMap[comment._id] = { ...comment, children: [] }
  })

  // Recursive function to assign child comments to parent comments
  const assignChildren = (comment: PostComment) => {
    const parent_id = comment.parent_id
    if (parent_id) {
      const parentComment = commentMap[parent_id]
      if (parentComment) {
        if (!parentComment.children) {
          parentComment.children = []
        }
        parentComment.children.push(commentMap[comment._id])
      }
    } else {
      commentTree.push(commentMap[comment._id])
    }
  }

  // Traverse and assign children recursively
  const traverseComments = (comments?: PostComment[]) => {
    comments?.forEach((comment) => {
      assignChildren(comment)
      if (commentMap[comment._id].children) {
        traverseComments(commentMap[comment._id].children)
      }
    })
  }

  // Start the traversal
  traverseComments(comments)

  return commentTree
}

const Post: React.FC = () => {
  const { slug } = useParams()
  const auth = useAuth()
  const isSignedIn = auth?.user
  const [replyId, setReplyId] = useState<string>('')

  const { data, isLoading } = useGetPostsBySlugQuery(slug as string, {
    skip: !slug,
  })

  const [getPostCommentsList, { data: comments, isLoading: isLoadingComment }] =
    useLazyGetPostCommentsListQuery()

  const [addCommentMutation] = useAddCommentMutation()

  useEffect(() => {
    if (data?._id) {
      getPostCommentsList(data?._id)
    }
  }, [data?._id])

  const SubmitCommentPwSchema = Yup.object().shape({
    content: Yup.string().required('Comment is required'),
  })

  const formik = useFormik({
    initialValues: {
      content: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        const { content } = values
        const result: any = await addCommentMutation({
          content,
          parent_id: replyId ? replyId : '',
          post_id: data?._id as string,
        })
        if (result?.error) {
          toast.error(result?.error?.data?.message || 'Something went wrong!', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
        } else {
          toast.success('Success! Your comment is awaiting moderation', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'light',
          })
          resetForm()
          if (data?._id) {
            getPostCommentsList(data?._id)
          }
          setReplyId('')
        }
        setSubmitting(false)
      } catch (error: any) {
        toast.error(error?.data?.message, {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'light',
        })
        setSubmitting(false)
      }
    },
    validationSchema: SubmitCommentPwSchema,
  })

  const setCommentReply = (value: string) => {
    if (value === replyId) setReplyId('')
    else setReplyId(value)
  }

  const renderComments = () => {
    if (isLoadingComment) {
      return <div>Loading comment</div>
    }
    if (!comments?.length) return null
    const sortedComments = buildCommentTree(comments)

    const commentHtml = sortedComments?.map((comment) => (
      <li key={comment?._id}>
        <div className="avatar">
          <img
            src={comment?.author_ref?.avatar || '/images/avatar4.jpeg'}
            alt=""
          />
        </div>
        <div className="comment_right clearfix">
          <div className="comment_info">
            By{' '}
            <span>
              {[
                comment?.author_ref?.first_name,
                comment?.author_ref?.last_name,
              ].join(' ')}
            </span>
            <span>|</span>
            {timeStampToDateShort(comment.created_at)}
            <span>|</span>
            {!comment?.parent_id ? (
              <span
                onClick={() => setCommentReply(comment?._id)}
                aria-hidden
                className={`reply-comment ${
                  comment?._id === replyId ? 'replying-active' : ''
                }`}
              >
                <i className="icon-reply" />
              </span>
            ) : null}
          </div>
          <p>{comment?.content}</p>
        </div>
        {comment?.children?.length ? (
          <ul className="replied-to">
            {comment?.children?.map((childrenComment) => (
              <li key={childrenComment?._id}>
                <div className="avatar">
                  <img
                    src={
                      childrenComment?.author_ref?.avatar ||
                      '/images/avatar4.jpeg'
                    }
                    alt=""
                  />
                </div>
                <div className="comment_right clearfix">
                  <div className="comment_info">
                    By{' '}
                    <span>
                      {[
                        childrenComment?.author_ref?.first_name,
                        childrenComment?.author_ref?.last_name,
                      ].join(' ')}
                    </span>
                    <span>|</span>
                    {timeStampToDateShort(childrenComment.created_at)}
                  </div>
                  <p>{childrenComment?.content}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    ))
    return <ul>{commentHtml}</ul>
  }

  const renderContent = () => {
    if (isLoading) {
      return <div>Loading data!</div>
    }
    if (!data) return <div>Post not found!</div>

    return (
      <div className="margin_60_35 container">
        <div className="row">
          <div className="col-lg-9">
            <div className="singlepost">
              {data?.thumbnail ? (
                <figure>
                  <img alt="" className="img-fluid" src={data?.thumbnail} />
                </figure>
              ) : null}
              <h2>{data?.name}</h2>
              <div className="postmeta">
                <ul>
                  {renderCategory()}
                  <li>
                    <i className="ti-calendar" />{' '}
                    {timeStampToDateShort(data?.created_at)}
                  </li>
                  <li>
                    <i className="ti-user" />{' '}
                    {[
                      data?.author_ref?.first_name,
                      data?.author_ref?.last_name,
                    ].join(' ')}
                  </li>
                  <li>
                    <Link to="/">
                      <i className="ti-comment" /> (14) Comments
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /post meta */}
              <div className="post-content">
                <p>{data?.content}</p>
              </div>
            </div>
            <div id="comments">
              <h5>Comments</h5>
              {renderComments()}
            </div>
            <hr />
            <h5>Leave a comment</h5>
            {!isSignedIn ? (
              <div>
                <Link to="/login">Sign in</Link> or{' '}
                <Link to="/register">Sign up</Link> to leave your comment
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="content"
                    id="content"
                    rows={6}
                    placeholder="Comment"
                    defaultValue={''}
                    onChange={formik.handleChange}
                    value={formik.values.content}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    id="submit2"
                    className="btn_1 add_bottom_15"
                  >
                    {replyId ? 'Reply' : 'Submit'}
                  </button>
                </div>
              </form>
            )}
          </div>
          <BlogSidebar />
        </div>
      </div>
    )
  }

  const renderCategory = () => {
    if (!data?.post_category_ref) return null
    return (
      <li>
        <Link to={`/post-category/${data?.post_category_ref?.slug}`}>
          <i className="ti-folder" /> {data?.post_category_ref?.name}
        </Link>
      </li>
    )
  }

  return (
    <>
      <section className="hero_single general">
        <div className="wrapper">
          <div className="container">
            <h1>Customer Advocates Blog</h1>
            <p>
              Customer Advocates helps grow your business using customer reviews
            </p>
          </div>
        </div>
      </section>
      {renderContent()}
    </>
  )
}

export default Post
